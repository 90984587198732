import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { IQuery } from '../shared/query';
import { InterceptorSkipHeader } from '../core/constants';
import { CustomHttpParamEncoder } from '../shared/custom-encoder';

@Injectable({
  providedIn: 'root'
})
export class ClientCommitmentsService {
  private _clientcommitmentBaseUrl: string = "clientcommitments";
  private _strategicClientUsersBaseUrl: string = "strategicclients";

  constructor(private http: HttpClient) { }

  public getPageTemplateResponseList(pageTemplateId: number, queryParams: IQuery) {
    const options = { 'refresh': 'true' };

    return this.http.post(`${this._clientcommitmentBaseUrl}/responses/${pageTemplateId}`, queryParams, { headers: options, observe: 'response' });
  }


  delegateResponse(id: number, data) {
    return this.http.put<any>(this._clientcommitmentBaseUrl + "/" + id + "/delegate", data);
  }

  // START: Temporay code. Will be updated after API is ready
  public getMappingSuggestions(pageResponseId: number, queryParams: IQuery) {
    let params = new HttpParams();

    for (const key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }

    const options = { 'refresh': 'true' };

    return this.http.get(`${this._clientcommitmentBaseUrl}/responses/${pageResponseId}/mapping/suggestions`, { headers: options, params: params, observe: 'response' });
  }

  public deleteLatestCommitmentReviewByClientId(clientId: number) {
    return this.http.delete(`${this._clientcommitmentBaseUrl}/clientdashboard/commitments/${clientId}/deleteLatestCommitmentReview`);
  }

  public getFormFieldSuggestionByUser(pageTemplateId: number) {
    return this.http.get(`${this._clientcommitmentBaseUrl}/suggestionsbyuser/${pageTemplateId}`);
  }

  public getClientsForCopy() {
    const params = new HttpParams();
    const options = { 'refresh': 'true' };
    return this.http.get(`${this._clientcommitmentBaseUrl}/clientdashboard/getrmclients`, { headers: options, params: params, observe: 'response' });
  }

  public copy(responseId: number, clientId: number) {
    return this.http.post(`${this._clientcommitmentBaseUrl}/${responseId}/copy`, { clientId: clientId });
  }

  public getProjectDetailForMapping(pageTemplateId: number, projectId: string, dataSource: string) {
    let url = `${this._clientcommitmentBaseUrl}/pagetemplates/${pageTemplateId}/projects/${projectId}`;
    let hasOptionalParams = false;
    if (dataSource && dataSource.toLocaleLowerCase() === "servicenow") {
      url += "?dataSource=servicenow";
      hasOptionalParams = true;
    }
    else if (dataSource && dataSource.toLocaleLowerCase() === "ecrm") {
      url += "?dataSource=ecrm";
      hasOptionalParams = true;
    }

    return this.http.get(url);
  }

  public getMappedProjectDetails(pageTemplateId: number, dataSource: string, commitmentResponseId: number) {
    const options = { 'refresh': 'true' };
    let url = `${this._clientcommitmentBaseUrl}/pagetemplates/${pageTemplateId}/responses/${commitmentResponseId}/mapped`;
    let hasOptionalParams = false;
    if (dataSource && dataSource.toLocaleLowerCase() === "servicenow") {
      url += "?dataSource=servicenow";
      hasOptionalParams = true;
    }
    else if (dataSource && dataSource.toLocaleLowerCase() === "ecrm") {
      url += "?dataSource=ecrm";
      hasOptionalParams = true;
    }
    return this.http.get(url, { headers: options});
  }

  public mapProject(targetPageResponseId: number, mappingPageResponseId: number, projectId: string) {
    return this.http.post(`${this._clientcommitmentBaseUrl}/responses/${targetPageResponseId}/map`, {
      mappingPageResponseId: mappingPageResponseId,
      clarityProjectNumber: projectId
    });
  }

  public mapOpportunity(targetPageResponseId: number, mappingPageResponseId: number, opportunityId: string) {
    return this.http.post(`${this._clientcommitmentBaseUrl}/responses/${targetPageResponseId}/map`, {
      mappingPageResponseId: mappingPageResponseId,
      opportunityId: opportunityId
    });
  }

  public mapPageTemplateResponse(targetPageResponseId: number, mappingPageResponseId: number, projectId: string) {
    return this.http.post(`${this._clientcommitmentBaseUrl}/responses/${targetPageResponseId}/map`, {
      mappingPageResponseId: mappingPageResponseId,
      clarityProjectNumber: projectId
    });
  }

  public unmapProject(pageTemplateId: number, pageResponseId: number, projectId: string, mappedPageResponseId: number) {
    return this.http.delete(`${this._clientcommitmentBaseUrl}/pagetemplates/${pageTemplateId}/pagetemplateresponses/${pageResponseId}/projects/${projectId}/unmap/${mappedPageResponseId}`);
  }

  public unmapOpportunity(pageTemplateId: number, pageResponseId: number, opportunityId: string, mappedPageResponseId: number) {
    return this.http.delete(`${this._clientcommitmentBaseUrl}/pagetemplates/${pageTemplateId}/pagetemplateresponses/${pageResponseId}/projects/${opportunityId}/unmap/${mappedPageResponseId}?dataSource=ecrm`);
  }

  public getPageTemplateById(id: number) {
    const params = new HttpParams();
    const options = { 'refresh': 'true' };
    return this.http.get(`${this._clientcommitmentBaseUrl}/pagetemplates/${id}`, { headers: options, params: params, observe: 'response' });
  }

  public getPageTemplateResponseById(id: number) {
    return this.http.get(`${this._clientcommitmentBaseUrl}/${id}`);
  }

  public deletePageResponse(id: number) {
    return this.http.delete(`${this._clientcommitmentBaseUrl}/${id}`);
  }

  public setPageTemplateResponseIsTrackedToFalse(id: number) {
    return this.http.delete(`${this._clientcommitmentBaseUrl}/${id}/SetPageTemplateResponseIsTrackedToFalse`);
  }

  updateMultiplePageResponses(responseData: any[]) {
    return this.http.put<any>(`${this._clientcommitmentBaseUrl}/updateresponses`, responseData);
  }

  createPageResponse(responseData) {
    return this.http.post<any>(`${this._clientcommitmentBaseUrl}`, responseData);
  }

  updatePageResponse(responseData) {
    return this.http.put<any>(`${this._clientcommitmentBaseUrl}/${responseData.id}`, responseData);
  }

  public exportPageTemplateResponseList(id: number, queryParams: IQuery) {
    const httpOptions = {
      headers: new HttpHeaders({
        refresh: "true"
      })
    };
    return this.http.post(`${this._clientcommitmentBaseUrl}/pagetemplate/${id}/exportResponses/`, queryParams, { headers: httpOptions.headers, observe: 'response' });
  }

  public exportPTResponseList(id: number, queryParams: IQuery, count: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        refresh: "true"
      })
    };
    return this.http.post(`${this._clientcommitmentBaseUrl}/pagetemplate/${id}/exportPTResponses/${count}`, queryParams, { headers: httpOptions.headers, observe: 'response' });
  }

  createStrategicCLientUsers(commitmentid,data) {
    return this.http.post<any>(`${this._strategicClientUsersBaseUrl}/${commitmentid}`, data);
  }

  GetStrategicCLientUsers(commitmentid) {
    return this.http.get<any>(`${this._strategicClientUsersBaseUrl}/${commitmentid}`);
  }

  getclientdashboardSummary(clientId: number, queryParams: any) {
    const options = { 'refresh': 'true' }
    options[InterceptorSkipHeader] = '';
    let params = new HttpParams({
      encoder: new CustomHttpParamEncoder()
    });
    for (let key of Object.keys(queryParams)) {
      const value = queryParams[key];
      params = params.append(key, value);
    }
    return this.http.get(`${this._clientcommitmentBaseUrl}/clientdashboard/summary/${clientId}`, { headers: options, params: params });
  }

  public getNewRMByClientDuns(duns: string) {
    return this.http.get(`${this._clientcommitmentBaseUrl}/getNewRMByClientDuns/${duns}`);
  }
}
