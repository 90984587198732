import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CurrentLoggedInUser, ROLES } from 'src/app/core/constants';
import { ManualCommitmentService } from 'src/app/services/manual-commitment.service';
import { UserService } from 'src/app/services/user.service';
import { IPager } from 'src/app/shared/pagination';
import { IQuery } from 'src/app/shared/query';
import { IUser } from 'src/app/shared/user';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { REGEX_PATTERNS } from 'src/app/core/constants';
import { ProjectsService } from 'src/app/services/projects.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-manual-commitment-list',
  templateUrl: './manual-commitment-list.component.html',
  styleUrls: ['./manual-commitment-list.component.css']
})

export class ManualCommitmentListComponent implements OnInit, OnDestroy {
  currentUser: IUser;
  queryParam: IQuery;
  pagerObject: IPager;
  currentPageNumber: number = 1;
  subs: Subscription[] = [];
  commitments = new Array<any>();
  commitmentSuggestions = new Array<any>();
  zindexCounter = 0;
  isShowConfirmDeleteDrawer = false;
  isShowConfirmSaveDrawer = false;
  canEdit = false;
  errorMsg: string = "";
  isError: boolean;
  filtered: boolean;
  filterForm: FormGroup;
  filterExists: boolean = true;
  isSubmitted = false;
  commitmentTitleSuggestions: string[] = [];
  dunsIdSuggestions: number[] = [];
  productTitleSuggestions: string[] = [];
  commitmentStatusSuggestions: string[] = [];
  clientTitleSuggestions: string[] = [];
  sortingObject;
  isAsc = true;
  enableSaveButton = false;
  originCommitments: Array<any> = [];
  commimentsTobeUpdated: Array<any> = [];
  currentItemDeleted = null;
  hasError = false;
  successMsg = '';
  pageTemplateId = environment.clientCommitmentPageTemplateId;
  filterColumn = '';
  displayColumns = [
    {
      key: 'IsTracked',
      text: 'Track',
      colspan: 1
    },
    {
      key: 'Id',
      text: 'Request Id',
      colspan: 1
    },
    {
      key: 'ECRMClientName',
      text: 'Client Name',
      colspan: 3
    },
    {
      key: 'ECRMClientDuns',
      text: 'DUNS',
      colspan: 2
    },
    {
      key: 'Title',
      text: 'Commitment Title',
      colspan: 2
    },
    {
      key: 'RequestorName',
      text: 'Requestor Name',
      colspan: 2
    },
    {
      key: 'RequestorEmail',
      text: 'Requestor Email',
      colspan: 2
    },
    {
      key: 'CreatedOn',
      text: 'Created Date',
      colspan: 1
    },
    {
      key: 'ModifiedOn',
      text: 'Modified Date',
      colspan: 1
    },
  ];

  constructor(private router: Router,
    private userService: UserService,
    private manualCommitmentService: ManualCommitmentService,
    private permissionService: PermissionsService
  ) { }

  ngOnInit(): void {
    this.initQueryParams();
    this.initFilterForm();
    this.getCurrentUser();
    this.refresh();
  }

  private initFilterForm() {
    this.filterForm = new FormGroup({
      clientTitle: new FormControl(''),
      productTitle: new FormControl(''),
      dunsId: new FormControl('', [Validators.pattern(REGEX_PATTERNS.duns_id),]),
      commitmentTitle: new FormControl(''),
      commitmentStatus: new FormControl(''),
    });
  }

  private getCurrentUser() {
    const user = localStorage.getItem(CurrentLoggedInUser);
    if (user) {
      this.currentUser = JSON.parse(user);
      console.log(this.currentUser);
    } else {
      this.currentUser = this.userService.getUserListData();
    }

    const roleTitle =  this.getValidRoleTitle(this.currentUser.roles);
    this.queryParam.roleTitle = roleTitle;
    this.canEdit = !!roleTitle;
  }

  private getValidRoleTitle(userRoles: any[]): any {
    const userRole = userRoles.find(el => el.roleTitle === ROLES.Account_Manager
      || el.roleTitle === ROLES.Relationship_Manager
      || el.roleTitle === ROLES.Sales_Executive
      || el.roleTitle === ROLES.CCT_Admin
      || el.roleTitle === ROLES.Admin
      || el.roleTitle === ROLES.Account_Executive);

      return userRole ? userRole.roleTitle : '';
  }

  private initQueryParams() {
    this.queryParam = Object.assign({}, {
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize,
      pageTemplateId: this.pageTemplateId
    });
  }

  private getList(isRefresh = false): any {
    const sub = this.manualCommitmentService.getList(this.queryParam, isRefresh).subscribe((res: any) => {
      console.log(res)
      this.commitments = res.body.commitments;
      this.commitments.forEach(el => {

        // Create origin commitments to tracking list change
        if (!this.originCommitments.some(c => c.id === el.id)) {
          const newOriginItem = _.cloneDeep(el);
          newOriginItem.isTracked = !!el.isTracked;
          this.originCommitments.push(newOriginItem);
        }

        // Keep changes when navigate
        const itemTobeUpdated = this.commimentsTobeUpdated.find(item => item.id === el.id);
        if (itemTobeUpdated) {
          el.isTracked = itemTobeUpdated.isTracked;
        }
        this.setSaveButtonState();
      });

      if (res.headers.get('X-pagination')) {
        this.pagerObject = Object.assign({}, JSON.parse(res.headers.get('X-pagination')) as IPager);
      }
    }, (error) => {
      console.log(error);
    });
    this.subs.push(sub);
  }

  public getCommitmentSuggestions(isRefresh = false): any {
    const sub = this.manualCommitmentService.getCommitmentSuggestions(isRefresh).subscribe((resp: any) => {
      this.commitmentSuggestions = resp.body.commitments;

      this.commitmentTitleSuggestions = this.commitmentSuggestions.map(commitment => commitment.title);
      this.dunsIdSuggestions = this.commitmentSuggestions.map(commitment => commitment.ecrmClientDuns);
      this.clientTitleSuggestions = this.commitmentSuggestions.map(commitment => commitment.ecrmClientName);

      this.commitmentSuggestions.forEach((commitment: any) => {
        commitment.products.forEach((product: any) => {
          if (!this.productTitleSuggestions.some(item => item === product.productName)) {
            this.productTitleSuggestions.push(product.productName);
          }
        });
      });

    }, (error) => {
      console.log(error);
    });
    this.subs.push(sub);
  }

  
  public changePage(page: number): void {
    this.queryParam.pageNumber = page;
    this.getList();
  }

  public refresh(isRefresh = true): any {
    this.originCommitments = [];
    this.commimentsTobeUpdated = [];

    this.getList(isRefresh);
    this.getCommitmentSuggestions();
  }

  public createNewCommitment(): void {
    this.router.navigate(['manual-commitment']);
  }

  public addZIndex(i: number): void {
    document.getElementById('actions-' + i).style.zIndex = (++this.zindexCounter).toString();
  }

  public onViewEdit(id): any {
    this.router.navigate(['manual-commitment/edit/', id]);
  }

  public onView(id): any {
    this.router.navigate(['manual-commitment/view/', id]);
  }

  public deleteCommitment(): any {
    this.hasError = false;
    const sub = this.manualCommitmentService.delete(this.currentItemDeleted.id).subscribe((res: any) => {
      this.getList(true);
      this.closeDeleteDrawer();
      this.notify('Commitment has been deleted successfully!');
    }, (error) => {
      this.hasError = true;
    });
    this.subs.push(sub);
  }

  public closeDeleteDrawer(): any {
    this.isShowConfirmDeleteDrawer = false;
  }

  public onDelete(itemDeleted): any {
    this.isShowConfirmDeleteDrawer = true;
    this.currentItemDeleted = itemDeleted;
  }

  public sort(column: string, type: string): void {
    if (this.filterColumn !== column) {
      this.filterColumn = column;
      this.isAsc = false;
    } else {
      this.isAsc = !this.isAsc;
    }
    this.queryParam.orderBy = this.isAsc ? this.filterColumn : `${this.filterColumn} desc`;

    this.queryParam.pageNumber = this.queryParam.pageNumber ?? 1;
    this.queryParam.pageSize = 10;
    this.refresh();
  }

  public filterList() {
    if (this.filterForm.valid) {
      this.initQueryParams();
      let filterValues = this.filterForm.value;
      if (filterValues['clientTitle'])
        this.queryParam.clientTitle = filterValues['clientTitle']
      if (filterValues['dunsId'])
        this.queryParam.dunsId = filterValues['dunsId']
      if (filterValues['productTitle'])
        this.queryParam.productTitle = filterValues['productTitle']
      if (filterValues['commitmentTitle'])
        this.queryParam.commitmentTitle = filterValues['commitmentTitle']
      if (filterValues['commitmentStatus'])
        this.queryParam.commitmentStatus = filterValues['commitmentStatus'];

      this.getList();
      this.filtered = true;
    }
  }

  public clearFilterList() {
    this.filtered = false;
    this.initFilterForm();
    this.initQueryParams();
    this.getList();
  }

  public onTrackChanged(currentItem: any): any {
    const originItem = this.originCommitments.find(item => item.id === currentItem.id);
    if (originItem) {
      if (originItem.isTracked !== currentItem.isTracked) {
        this.commimentsTobeUpdated.push(currentItem);
      } else {
        this.commimentsTobeUpdated = this.commimentsTobeUpdated.filter(item => item.id !== currentItem.id);
      }
      this.setSaveButtonState();
    }
  }

  public openSaveDrawer(): any {
    this.isShowConfirmSaveDrawer = true;
  }

  public closeSaveDrawer(): any {
    this.isShowConfirmSaveDrawer = false;
  }

  public submitChanges(): any {
    this.hasError = false;
    const sub = this.manualCommitmentService.bulkUpdate(this.commimentsTobeUpdated).subscribe(_ => {
      this.originCommitments = this.originCommitments.filter(item => !this.commimentsTobeUpdated.some(el => el.id === item.id));
      this.commimentsTobeUpdated = [];
      this.closeSaveDrawer();
      this.notify('Commitment(s) have been updated successfully!');
      this.refresh(true);
    }, (error) => {
      this.hasError = true;
      this.notify();
    });
    this.subs.push(sub);
  }

  public getStatusCSSClass(commitment: any) {
    return commitment.isTracked ? 'alert-message success' : 'alert-message';
  }

  public setSaveButtonState(): any {
    this.enableSaveButton = this.commimentsTobeUpdated.length > 0;
  }

  public notify(msg = '') {
    this.successMsg = msg;
    var element = document.getElementById("notify");
    element.className = "show";
    setTimeout(function () { element.className = element.className.replace("show", ""); }, 3000);
  }

  private distinct(value, index, array) {
    return array.indexOf(value) === index;
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
