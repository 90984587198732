<div class="commitment-intake-container">
  <div class="heading-container">
    <div class="  bank-name" [ngClass]="(!isClient || isClientAdmin)?'':'bank-name-single-fi'">
      <div class="label-text-search" *ngIf="!isClient || isClientAdmin">Search Client By:</div>
      <div class="d-flex">
        <div class="bank-name-value " *ngIf="!isClient">
          <div class="fdl-select mid-width">
            <select [(ngModel)]="clientTypeSearch" (change)="clientTypeChange();" class="form-control">
              <option value="duns">DUNS</option>
              <option value="gud">GUD</option>
            </select>
          </div>
        </div>
        <div class="bank-name-value cursor-text">
          <p *ngIf="isClient && !isClientAdmin" class="client-name-wrapper">{{clientText}}</p>
          <app-type-ahead [(ngModel)]="clientText" [options]="clntSuggestion" optionField="titleWithDuns"
                          *ngIf="!isClient || isClientAdmin" [placeholder]="'Type Client to search'" (selectDone)="clientSelected()">
          </app-type-ahead>
        </div>
      </div>
    </div>
    <!-- Please add a class of mt-0 if the line number 57 and 58 not display on the UI -->
    <div class="last-refresh-note">
      Last login on <span>{{LastLoginTimeStamp}}</span>
    </div>
  </div>
  <div class="bank-name-label" *ngIf="clientId==0 || clientId==-1">
    Please select the client to view the commitments.
  </div>
  <div class="bank-name-label" *ngIf="commitmentStats['Total']==0 && clientId!=0 && clientId!=-1 && !stillLoading">
    No
    Commitments Available.
  </div>
  <div class="box-container statistics pl-0">
    <div class="m-0 p-0">
      <section class="col-lg-12 advance-search-wrapper">
        <div class="advance-search-container">

          <div class=" form-border mb-3 collapse-view" autocomplete="off">
            <h2 class="h4 collapse-arrow  search-text" data-toggle="collapse" href="#collapseFilter"
                (click)="openFilterPage();">
              Advanced Search
            </h2>

            <div class="icon-wrapper pdf-icon-btn"
                 [hidden]="isRowsLoading || stillLoading || isProductsLoading || clientId === 0 || clientId === -1">
              <button class="download-icon" (click)="renderPdfExport()">
                <div class="icon"></div>
              </button>
            </div>
            <div id="PPTDiv" class="fdl-2 btn-review-commitment" data-toggle="tooltip" data-placement="top"
                 title="Download PPT extract"
                 [hidden]="isClientExecutive || isClientAdmin || isRowsLoading || stillLoading || isProductsLoading || clientId === 0 || clientId === -1">
              <button type="button" id="btnPPT" ref="tooltip" class="btn fdl-btn btn-primary-orange ml-2"
                      (click)="renderPPTExport()">
                <span style="align-content:center">PPT</span>
              </button>
            </div>
            <!-- <div id="ReviewTrailDiv" class="fdl-2 btn-review-commitment" data-toggle="tooltip" data-placement="top"
              title="Click to view all review comments"
              [hidden]="isClientExecutive || clientId === 0 || clientId === -1 || commitmentReviews.length === 0">
              <button type="button" id="btnReviewCommitment" (click)="showDrawerForReviewTrailer()"
                class="btn fdl-btn btn-primary-orange ml-3"
                *ngIf="!isClientExecutive && clientId !== 0 && clientId !== -1">
                <span class="material-icons"> rate_review </span>
                <span class="btn-title">Review Trail</span>
              </button>
            </div> -->
            <div id="ReviewTrailDiv" class="fdl-2 btn-review-commitment" data-toggle="tooltip" data-placement="top"
                 title="Click to view all review comments"
                 [hidden]="isClientExecutive || isClientAdmin || clientId === 0 || clientId === -1">
              <!--Audit Trail Button Starts  -->
              <button type="button" id="btnReviewCommitment" ref="tooltip" class="btn fdl-btn btn-primary-orange ml-3"
                      (click)="redirectToClientReviewTrailOverall()"
                      *ngIf="!isClientExecutive && !isClientAdmin && clientId !== 0 && clientId !== -1">
                <span class="material-icons"> rate_review </span>
                <span class="btn-title">Review Trail</span>
              </button>
              <!--Audit Trail Button Ends  -->
            </div>
            <div class="filter-form-container-column2 show" id="collapseFilter">
              <div class="filter-section mr-10 ">
                <label>Line of Business: </label>
                <div class="filter-section line-of-business">
                  <app-type-ahead [(ngModel)]="selectedLOB" [options]="LOB" placeholder="Type to search" [label]="''">
                  </app-type-ahead>
                </div>
              </div>
              <div class="filter-section search-right ">
                <label> Product:</label>
                <div class="filter-section product">
                  <app-type-ahead [(ngModel)]="products" [options]="productsOption" placeholder="Type to search">
                  </app-type-ahead>
                </div>
              </div>
              <div class="filter-section search-left target-wrapper">
                <label class="">Target Delivery Date:</label>
                <div class="filters-date">
                  <div class="filter-section-date mr-3">
                    <label class="filter-section-date">From: </label>
                    <input class="form-control" max="{{toMonth}}" [(ngModel)]="fromMonth" (change)="setDateFrom($event)"
                           type="month" id="birthday" name="birthday">
                  </div>
                  <div class="filter-section-date mr-0">
                    <label class="filter-section-date">To: </label>
                    <input class="form-control" min="{{fromMonth}}" [(ngModel)]="toMonth" (change)="setDateFrom($event)"
                           type="month" id="birthdayd" name="fromDate">
                  </div>
                </div>
              </div>
              <div class="fdl-2 d-flex width100">
                <button type="button" class="btn fdl-btn btn-primary-orange ml-3" aria-label="Search"
                        (click)="fullAdvanceSearch()">
                  Search
                </button>
                <button type="button" class="btn fdl-btn btn-secondary-light ml-3" aria-label="Reset"
                        (click)="cancelSearch('main')">
                  Reset
                </button>
                <div class="dropdown ml-auto view-contacts-dropdown">
                  <button class="btn btn-primary-orange dropdown-toggle btn-contacts" id="dropdownContactButton" type="button" data-toggle="dropdown" aria-expanded="false">
                    <div>Contacts</div>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownContactButton">
                    <app-commitment-dashboard-contacts [summary]="summary"></app-commitment-dashboard-contacts>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class=" m-0 separate-form-container p-0">
      <section class="col-lg-12 summary-main-container">
        <div class="app-col-1">
          <div class="summary-main-container-panel" style="display: flex;">
            <div class="box-container statistics pl-0">
              <h2>Commitment Statistics</h2>
              <div class="box-wrapper ">
                <div role="status" *ngIf="stillLoading" aria-label="Please wait, do not refresh the page"
                     class="ccdrms-loader" style="position:relative;margin-top:20%"></div>
                <div class="swtich-container" *ngIf="!stillLoading">
                  <div [ngClass]="isSla?'switch-wrapper':''">
                    <div *ngIf="isSla" class="tab-switch" [ngClass]="slaDashActive?'active':''"
                         (click)="slaActiveTab(true)">
                      Non-SLA
                    </div>
                    <div class="tab-switch " *ngIf="isSla" [ngClass]="slaDashActive?'':'active'"
                         (click)="slaActiveTab(false)">
                      SLA
                    </div>
                  </div>
                  <div class="total-count" [ngClass]="slaDashActive?'d-flex':'d-none'">
                    <div class="value">
                      Total Commitment(s):
                    </div>
                    <div class="count-badge">
                      {{commitmentStats['Total']}}
                    </div>
                  </div>
                  <div class="filter-section" style="margin-right:0px !important;"
                       [ngClass]="slaDashActive?'d-none':'d-flex'">
                    <label>Product/Function:</label>
                    <div class="search-filter">
                      <app-type-ahead [(ngModel)]="selectedSlaProduct" [options]="slaProductSearch"
                                      (selectDone)="slaGraphChange()" [placeholder]="'Type to search'"></app-type-ahead>
                    </div>
                  </div>
                </div>
                <!------------------SLA-->
                <div class="scroll-horizontal sla" [ngClass]="slaDashActive?'d-none':'d-flex'">
                  <ul class="box commitment-status sla">
                    <li class="box product-chat">
                      <div class="status-wrapper">
                        <div class="status-count-container">
                          <div style="align-items:center;" class="filter-section">
                            <label><strong>SLA Goal</strong></label>
                          </div>
                        </div>
                        <div class="chart-container">
                          <div class="chart">
                            <div class="chart">
                              <div class="semi-donut margin" style="--percentage: {{slaPercentage}}; --fill: #0080BF; ">
                                {{slaPercentage}}%
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li class="box full-width-chart">
                      <div class="chart" *ngIf="showGraph">
                        <canvas id="areaChart"></canvas>
                      </div>
                    </li>
                  </ul>
                </div>
                <!----------------------------SLA END-->
                <div class="scroll-horizontal non-sla flex-column" *ngIf="!stillLoading"
                     [ngClass]="slaDashActive?'d-flex':'d-none'">
                  <ul class="box commitment-status">
                    <li class="box active">
                      <div class="status-wrapper">
                        <div class="status-count-container">
                          <div class="status active"> In Progress</div>
                          <div class="status-count">{{ this.commitmentStatsCount['In Progress']}}</div>
                        </div>
                        <div class="chart-container">
                          <div class="chart">
                            <div class="chart">
                              <div class="semi-donut margin"
                                   style="--percentage: {{commitmentStats['In Progress']}}; --fill: #0080BF;">
                                {{commitmentStats['In Progress']}}%
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="box delivered">
                      <div class="status-wrapper">
                        <div class="status-count-container">
                          <div class="status purple">Delivered</div>
                          <div class="status-count">{{ this.commitmentStatsCount['Delivered']}}</div>
                        </div>
                        <div class="chart-container">
                          <div class="chart">
                            <div class="chart">
                              <div class="semi-donut margin"
                                   style="--percentage: {{commitmentStats['Delivered']}}; --fill: #887BB0;">
                                {{commitmentStats['Delivered']}}%
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </li>
                    <li class="box fullfilled">
                      <div class="status-wrapper">
                        <div class="status-count-container">
                          <div class="status green">Completed</div>
                          <div class="status-count">{{ this.commitmentStatsCount['Completed']}}</div>
                        </div>
                        <div class="chart-container">
                          <div class="chart">
                            <div class="semi-donut margin"
                                 style="--percentage: {{ this.commitmentStats['Completed']}}; --fill: #54B34A;">
                              {{
                              this.commitmentStats['Completed']
                              }}%
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="box on-hold">
                      <div class="status-wrapper">
                        <div class="status-count-container">
                          <div class="status yellow"> On Hold</div>
                          <div class="status-count">{{ this.commitmentStatsCount['On Hold']}}</div>
                        </div>
                        <div class="chart-container">
                          <div class="chart">
                            <div class="semi-donut margin"
                                 style="--percentage: {{ this.commitmentStats['On Hold']}}; --fill: #FFC626;">
                              {{
                              this.commitmentStats['On Hold']
                              }}%
                            </div>
                          </div>

                        </div>
                      </div>
                    </li>
                    <li class="box cancelled">
                      <div class="status-wrapper">
                        <div class="status-count-container">
                          <div class="status grey">Canceled</div>
                          <div class="status-count">{{ this.commitmentStatsCount['Canceled']}}</div>
                        </div>
                        <div class="chart-container">
                          <div class="chart">
                            <div class="semi-donut margin"
                                 style="--percentage: {{ this.commitmentStats['Canceled']}}; --fill: #757575; ">
                              {{
                              this.commitmentStats['Canceled']
                              }}%
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>

                </div>
                <div class="legend-wrapper" *ngIf="!stillLoading">
                  <span class="legend-text">Overall Progress</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="app-col-2">
          <div class="box-container commitments-calendar">
            <h2>Delivery Calendar</h2>
            <div class="box-wrapper"
                 *ngIf="commitmentCalendarNumbers.length==0 && commitmentCalendar && !dataCommitmentCalendarError">
              <div class="no-data-message-container">
                <div class="product-wrapper no-data-message">
                  <h3>No Data Available</h3>
                </div>
              </div>

            </div>
            <div class="box-wrapper" *ngIf="dataCommitmentCalendarError">
              <div class="no-data-message-container">
                <div class="product-wrapper no-data-message">
                  <h3>There was a problem. Please try again later.</h3>
                </div>
              </div>

            </div>
            <div class="box-wrapper "
                 [ngClass]="(commitmentCalendarNumbers.length!=0 || !commitmentCalendar) && !dataCommitmentCalendarError?'d-flex':'d-none'">
              <div class="filter-area">
                <div class="swtich-container mb-0">
                  <div class="switch-wrapper">
                    <div class="tab-switch" id="month" [ngClass]="typeOfCommitmentCalander=='month'?'active':''"
                         (click)="calanderCommitment('month')">Monthly</div>
                    <div class="tab-switch " id="qtr" [ngClass]="typeOfCommitmentCalander=='qtr'?'active':''"
                         (click)="calanderCommitment('qtr')">Quarterly</div>
                    <div class="tab-switch" id="year" [ngClass]="typeOfCommitmentCalander=='year'?'active':''"
                         (click)="calanderCommitment('year')">Yearly</div>
                  </div>
                </div>
              </div>

              <div class="chart" *ngIf="!commitmentCalendar">
                <div role="status" aria-label="Please wait, do not refresh the page" class="ccdrms-loader"
                     style="position:relative;margin-top:20%"></div>
              </div>
              <div class="chart" [ngClass]="commitmentCalendar?'d-flex':'d-none'">
                <canvas id="areaChart2"></canvas>
                <div class="legend-wrapper ml-0 mr-0">
                  <span class="legend-text">
                    <i class="material-icons">info</i>
                    Click on delivery state to exclude from the calendar
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 p-0" *ngIf="alertInput.message !== ''">
          <!-- Error Msg Alert -->
          <div class="alert alert-dismissible alert-danger" *ngIf="alertInput.alertType === alertType.Error">
            <button type="button" class="close" data-dismiss="alert" aria-label="close"
                    (click)="setAlertMsgToEmpty()">
              ×
            </button>
            <i class="material-icons">warning</i>
            <p [innerHtml]="alertInput.message"></p>
          </div>
          <!-- Success Msg Alert -->
          <div class="alert alert-success alert-dismissible" *ngIf="alertInput.alertType === alertType.Success">
            <button type="button" class="close" data-dismiss="alert" aria-label="close"
                    (click)="setAlertMsgToEmpty()">
              ×
            </button>
            <i class="material-icons" aria-hidden="true">check_circle</i>
            <p [innerHtml]="alertInput.message"></p>
          </div>
        </div>
        <div id="tabSection" class="box-container commitments-table">
          <div class="commitments-tabs-wrapper">
            <ul class="nav nav-tabs scoped-tabs" role="tablist">
              <li class="nav-item">
                <a class="nav-link active show" id="tab-1" role="tab" aria-selected="false"
                   href="#tab13" data-toggle="tab">
                  Project Commitments <div class="total-count">
                    <div class="count-badge">{{projectTotalCount || 0}}</div>
                  </div>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link inactive show" id="tab-2" role="tab" aria-selected="false"
                   href="#tab14" data-toggle="tab">
                  Non Project Commitments <div class="total-count">
                    <div class="count-badge">{{nonProjectTotalCount || 0}}</div>
                  </div>
                </a>
              </li>
              <!--<li class="nav-item"> <a class="nav-link" id="tab-2"
                  *ngIf="!isClientExecutive && (autoTaggedCommitmentsPagerObject.totalCount > 0 || isAutoTaggedAdvanceSearched)"
                  role="tab" href="#tab14" aria-selected="true" data-toggle="tab">Auto-Tagged Commitments <div
                    class="total-count">
                    <div class="count-badge">{{autoTaggedCommitmentsPagerObject.totalCount}}</div>
                  </div></a>
              </li>-->
            </ul> <!-- Tab panes -->
            <div class="tab-content" id="myTabContentForClientDashboard">
              <div class="tab-pane fade active show" id="tab13" role="tabpanel" aria-labelledby="tab-1">
                <div class="box-container commitments-table mt-0">
                  <div class="box-wrapper table-container ">
                    <div class="filter-wrapper">
                      <div class="advance-filter-container">
                        <div class="search-filter project-number">

                          <app-type-ahead [placeholder]="'Type to search'" [(ngModel)]="selectedProjectNumber"
                                          [options]="clarityProjectNumbersSuggestions" (selectDone)="onSelectProjectNumberDone()"
                                          (clearText)="onClearText('ClarityProjectNumber')"
                                          (ngModelChange)="onModelChange('ClarityProjectNumber')"
                                          [label]="'Project Number:'"></app-type-ahead>
                        </div>
                        <div class="search-filter title">

                          <app-type-ahead [placeholder]="'Type to search'" [(ngModel)]="selectedTitle"
                                          [options]="rowsTitleSuggestionsProject" (selectDone)="onSelectTitleDone()"
                                          (clearText)="onClearText('CommitmentTitle')" (clearText)="onClearText('CommitmentTitle')"
                                          (ngModelChange)="onModelChange('CommitmentTitle')" [label]="'Title:'"></app-type-ahead>
                        </div>
                        <div class="search-filter mr-2">
                          <label>State:</label>
                          <div class="mid-width">
                            <ng-select [multiple]="true" [items]="commitmentStatusesSuggestions" [closeOnSelect]="false"
                                       [virtualScroll]="true" [clearOnBackspace]="false" placeholder="Type to search"
                                       [(ngModel)]="commitmentStatusList" [ngModelOptions]="{standalone: true}"
                                       (change)="onStateChanged($event)" bindLabel="name" bindValue="id" class="ng-select">
                              <!-- <ng-option value="">All</ng-option> -->
                              <ng-template ng-option-tmp let-itemTemp="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                                <span title="{{itemTemp.name}}"> {{itemTemp.name}} </span>
                              </ng-template>
                              <ng-template ng-multi-label-tmp>
                                <span class="ng-value-label">{{getStateSelectedLabel()}}</span>
                              </ng-template>
                            </ng-select>
                          </div>
                        </div>
                        <div class="fdl-2 button-position ">
                          <button type="button" class="btn fdl-btn btn-primary-orange ml-3" aria-label="Search"
                                  (click)="advanceSearch()">
                            Search
                          </button>
                          <button type="button" class="btn fdl-btn btn-secondary-light ml-3" aria-label="Reset"
                                  (click)="cancelSearch('All')">
                            Reset
                          </button>
                        </div>
                      </div>
                      <div class="icon-wrapper" style="margin-left: auto;">
                        <button (click)="exportExcel(false)" class="download-icon">
                          <div class="icon"> </div>
                        </button>
                      </div>
                    </div>
                    <div class="table-section cct-table s-overflow-auto">
                      <div class="no-data-message-container p-0 pt-2"
                           *ngIf="paginatedAllRows.length==0 && !commitmentsError">
                        <div class="product-wrapper no-data-message">
                          <h3>No Data Available</h3>
                        </div>
                      </div>
                      <div class="no-data-message-container p-0 pt-2" *ngIf=" commitmentsError">
                        <div class="product-wrapper no-data-message">
                          <h3>There was a problem. Please try again later.</h3>
                        </div>
                      </div>
                      <app-data-table-updated *ngIf="paginatedAllRows.length>0" [ngShow]="!isRowsLoading"
                                              (sortingClicked)="applySorting($event)" [headerColumns]="headerColumns" [keys]="keys"
                                              [rows]="paginatedAllRows" (buttonClicked)="navigate($event, false)" [filterColumn]="filterColumn"></app-data-table-updated>
                      <app-pagination *ngIf="allRowsTotalPages > 1 && !isRowsLoading"
                                      [pagerObject]="{totalPages : allRowsTotalPages, currentPage: allRowsPage}"
                                      (pagingChanged)="onAllRowsPageChange($event)"></app-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade inactive hidden" id="tab14" role="tabpanel" aria-labelledby="tab-2">
                <div class="box-container commitments-table mt-0">
                  <div class="box-wrapper table-container ">
                    <div class="filter-wrapper">
                      <div class="advance-filter-container">
                        <div class="search-filter project-number">

                          <app-type-ahead [placeholder]="'Type to search'" [(ngModel)]="selectedProjectNumberNonProject"
                                          [options]="clarityProjectNumbersSuggestionsNonProject" (selectDone)="onSelectProjectNumberDoneNonProject()"
                                          (clearText)="onClearTextNonProject('ClarityProjectNumber')"
                                          (ngModelChange)="onModelChangeNonProject('ClarityProjectNumber')"
                                          [label]="'Project Number:'"></app-type-ahead>
                        </div>
                        <div class="search-filter title">

                          <app-type-ahead [placeholder]="'Type to search'" [(ngModel)]="selectedTitleNonProject"
                                          [options]="rowsTitleSuggestionsNonProject" (selectDone)="onSelectTitleDoneNonProject()"
                                          (clearText)="onClearTextNonProject('CommitmentTitle')"
                                          (ngModelChange)="onModelChangeNonProject('CommitmentTitle')" [label]="'Title:'"></app-type-ahead>
                        </div>
                        <div class="search-filter mr-2">
                          <label>State:</label>
                          <div class="mid-width">
                            <ng-select [multiple]="true" [items]="commitmentStatusesSuggestionsNonProject" [closeOnSelect]="false"
                                       [virtualScroll]="true" [clearOnBackspace]="false" placeholder="Type to search"
                                       [(ngModel)]="commitmentStatusListNonProject" [ngModelOptions]="{standalone: true}"
                                       (change)="onStateChangedNonProject($event)" bindLabel="name" bindValue="id" class="ng-select">
                              <!-- <ng-option value="">All</ng-option> -->
                              <ng-template ng-option-tmp let-itemTemp="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                                <span title="{{itemTemp.name}}"> {{itemTemp.name}} </span>
                              </ng-template>
                              <ng-template ng-multi-label-tmp>
                                <span class="ng-value-label">{{getStateSelectedLabelNonProject()}}</span>
                              </ng-template>
                            </ng-select>
                          </div>
                        </div>
                        <div class="fdl-2 button-position ">
                          <button type="button" class="btn fdl-btn btn-primary-orange ml-3" aria-label="Search"
                                  (click)="advanceSearchNonProject()">
                            Search
                          </button>
                          <button type="button" class="btn fdl-btn btn-secondary-light ml-3" aria-label="Reset"
                                  (click)="cancelSearchNonProject('All')">
                            Reset
                          </button>
                        </div>
                      </div>
                      <div class="icon-wrapper" style="margin-left: auto;">
                        <button (click)="exportExcelManual(false)" class="download-icon">
                          <div class="icon"> </div>
                        </button>
                      </div>
                    </div>
                    <div class="table-section cct-table s-overflow-auto">
                      <div class="no-data-message-container p-0 pt-2"
                           *ngIf="paginatedManualRows.length==0 && !commitmentsError">
                        <div class="product-wrapper no-data-message">
                          <h3>No Data Available</h3>
                        </div>
                      </div>
                      <div class="no-data-message-container p-0 pt-2" *ngIf=" commitmentsError">
                        <div class="product-wrapper no-data-message">
                          <h3>There was a problem. Please try again later.</h3>
                        </div>
                      </div>
                      <app-data-table-updated *ngIf="paginatedManualRows.length>0" [ngShow]="!isRowsLoading"
                                              (sortingClicked)="applySortingNonProject($event)" [headerColumns]="headerColumns" [keys]="keys"
                                              [rows]="paginatedManualRows" (buttonClicked)="navigate($event, false)" [filterColumn]="filterColumnNonProject"></app-data-table-updated>
                      <app-pagination *ngIf="manualRowsTotalPages > 1 && !isRowsLoading"
                                      [pagerObject]="{totalPages : manualRowsTotalPages, currentPage: manualRowsPage}"
                                      (pagingChanged)="onManualRowsPageChange($event)"></app-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <div id="sideButtonDiv" class="nav nav-tabs scoped-tabs" role="tablist">
                <button class="myButton" id="sideButton" data-toggle="tab" data-target="#tab15" type="button" role="tab"
                        aria-controls="tab15" aria-selected="true" (click)="toggleNotpadSection('commitment')">
                  Commitments by
                  Products
                  <span [ngClass]="{'arrow-left':(!isNotpadSectionVisible), 'arrow-right':(isNotpadSectionVisible)}"></span>
                </button>
                <button class="myButton" id="sideButton2" data-toggle="tab" data-target="#tab16" type="button"
                        role="tab" aria-controls="tab16" aria-selected="false" (click)="toggleNotpadSection('note')">
                  Notes
                  <span [ngClass]="{'arrow-left':(!isNotpadSectionVisible), 'arrow-right':(isNotpadSectionVisible)}"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div id="notepadSection" class="box-container product-details">
          <div class="commitments-tabs-wrapper">
            <div class="tab-content">
              <div class="tab-pane fade active show" id="tab15" role="tabpanel" aria-labelledby="tab-1">
                <div id="tab15boxWrapper" class="box-wrapper">
                  <div *ngIf="!ngShows" role="status" aria-label="Please wait, do not refresh the page"
                       class="ccdrms-loader"> </div>
                  <ul class="product-list" *ngIf="ngShows"
                      [ngClass]="commitmentProducts && commitmentProducts.length > 7 ? 'border-bottom':''">
                    <li *ngFor="let k of pageProducts">
                      <div class="product-wrapper">
                        <h3 title="{{k.productName}}">{{k.productName}} </h3>
                        <p title="{{k.productDescription}}">{{k.productDescription}}</p>
                      </div>
                      <div class="two-col">
                        <div class="name">
                          <span class="badge">{{k.totalCommitments}}</span>
                        </div>
                      </div>
                    </li>

                    <!-- No data available -->
                    <li *ngIf="commitmentProducts && commitmentProducts.length == 0">
                      <div class="product-wrapper no-data-message">
                        <h3>No Data Available</h3>
                      </div>
                    </li>
                    <li *ngIf="cBProductsError">
                      <div class="product-wrapper no-data-message">
                        <h3>There was a problem.<br />Please try again later.</h3>
                      </div>
                    </li>
                  </ul>
                  <span *ngIf="ngShows">
                    <app-pagination [template]="'2'" *ngIf="pagerObjectProduct && pagerObjectProduct.totalPages > 1 "
                                    [pagerObject]="pagerObjectProduct" (pagingChanged)="changePageProduct($event)"></app-pagination>
                  </span>
                </div>
              </div>
              <div class="tab-pane fade" id="tab16" role="tabpanel" aria-labelledby="tab-1">
                <div id="tab16boxWrapper" class="box-wrapper">
                  <div id="noteMainDiv">
                    <textarea id="noteTextarea" [(ngModel)]="note" class="form-control" *ngIf="noteVisibilityCheck()"
                              [disabled]="clientId === 0 || clientId === -1"></textarea>
                    <pre id="notePreTag" [hidden]="noteVisibilityCheck()">{{note}}</pre>
                    <button *ngIf="noteVisibilityCheck() && clientId !== 0 && clientId !== -1" type="button"
                            class="btn fdl-btn myButton" (click)="saveNote()">
                      Save
                    </button>
                    <p *ngIf="noteVisibilityCheck()">
                      Reminder: Notes are Client Visible.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  </div>
  <!-- Basic Info Page Ends  -->
  <!-- Drawer Starts - Please use the drawer patten instead of this static HTML this is just an example -->

  <div id="drawerDialog" *ngIf="drawerActive" [ngClass]="drawerActive?'d-block ':'d-none'">
    <div tabindex="-1" aria-labelledby="addContacts" aria-expanded="true" role="region"
         class="drawer drawer-right dw-xs-12 dw-sm-8 dw-md-5 fold open">
      <form [formGroup]="reasonForm" autocomplete="off">
        <div class="drawer-contents commitment-dw  col-xs-12 col-sm-12 col-md-12 ">
          <div class="dw-header">
            <div class="modal-header-container">
              <button type="button" data-dismiss="modal" tabindex="0" autofocus="" aria-label="Close popup"
                      class="arrow-dw" (click)="closeDrawer();">
                <span class="material-icons">
                  arrow_back
                </span>
              </button>

              <h4 class="modal-title  ">
                {{detailsCommitment.commitmentTitle}}
                <!--<p>{{detailsCommitment.commitmentDescription}}</p>-->
              </h4>
            </div>

            <!-- <div class="right-wrapper d-flex" style="display: flex;">
              <button (click)="closeDrawer();" type="button" data-dismiss="modal" aria-label="Close popup" id="closeDw" class="close"><span aria-hidden="true">×</span></button>
            </div> -->
          </div>

          <div class="dw-body">

            <div class="dw-box">
              <div class="dw-box-title-container" role="button" data-toggle="collapse" data-target="#collapseStepOne"
                   aria-expanded="true">
                <h3>Commitment Details</h3>
              </div>
              <div class="dw-box-content-container collapse show" id="collapseStepOne" aria-labelledby="headingOne">
                <ul class="content-list mb-2">
                  <li class="flex-column">
                    <div class="title">Title</div>
                    <div class="content">
                      {{detailsCommitment.commitmentTitle || 'Not Available'}}
                    </div>
                  </li>
                </ul>
                <ul class="content-list mb-2" *ngIf="!(isClientExecutive || isClientAdmin)">
                  <li class="flex-column">
                    <div class="title">Description</div>
                    <div class="content">{{detailsCommitment.commitmentDescription || 'Not Available'}}</div>
                  </li>
                </ul>
                <ul class="content-list mb-2">
                  <li class="half-width products">
                    <div class="title">Product(s)</div>
                    <div class="content">{{detailsCommitment.product || 'Not Available'}}</div>
                  </li>
                </ul>
                <ul class="content-list">
                  <!-- <li class="half-width">
                    <div class="title">
                      Type <i id="commitmentTypeTooltip" class="fa fa-question-circle question-icon text-muted"
                        ref="tooltip"></i>
                    </div>
                    <div class="content">{{detailsCommitment.commitmentType || 'Not Available'}}</div>
                  </li> -->
                  <li class="half-width">
                    <div class="title">
                      State <i title="State of the Commitment"
                               class="fa fa-question-circle question-icon text-muted" ref="tooltip" data-toggle="tooltip"
                               data-animation="false"></i>
                    </div>
                    <div class="content"> {{detailsCommitment.commitmentStatus || 'Not Available'}}</div>
                  </li>
                  <!-- <li class="half-width">
                    <div class="title">Delivery Date <i title="Planned Delivery Date for commitment" class="fa fa-question-circle question-icon text-muted" ref="tooltip" data-toggle="tooltip" data-animation="false"></i></div>
                    <div class="content">{{detailsCommitment.plannedCommittedDeliveryDate | date: content['dateFormat']}}</div>
                  </li> -->
                  <li class="half-width">
                    <div class="title">Fulfillment ID </div>
                    <div class="content">{{detailsCommitment.clarityProjectNumber || 'Not Available'}}</div>
                  </li>

                  <li class="half-width">
                    <div class="title">Delivery Owner </div>
                    <div class="content"
                         *ngIf="!isDeliveryOwnerShown() || (!isDeliveryOwnerTextShown() && !isDeliveryOwnerLinkShown())">
                      Not Available
                    </div>
                    <div class="content" *ngIf="isDeliveryOwnerShown() && isDeliveryOwnerLinkShown()">
                      <a href="mailto: {{detailsCommitment.deliveryOwnerEmailAddress}}">{{detailsCommitment.deliveryOwner}}</a>
                    </div>
                    <div class="content"
                         *ngIf="isDeliveryOwnerShown() && isDeliveryOwnerTextShown() && !isDeliveryOwnerLinkShown()">
                      <label>{{detailsCommitment.deliveryOwner}}</label>
                    </div>
                  </li>
                  <li class="half-width">
                    <div class="title">Project Manager </div>
                    <div class="content"
                         *ngIf="!isProjectManagerShown() || (!isProjectManagerTextShown() && !isProjectManagerLinkShown())">
                      Not Available
                    </div>
                    <div class="content" *ngIf="isProjectManagerShown() && isProjectManagerLinkShown()">
                      <a href="mailto: {{detailsCommitment.projectManagerEmailAddress}}">{{detailsCommitment.projectManager}}</a>
                    </div>
                    <div class="content"
                         *ngIf="isProjectManagerShown() && isProjectManagerTextShown() && !isProjectManagerLinkShown()">
                      <label>{{detailsCommitment.projectManager}}</label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="dw-box">
              <div class="dw-box-title-container" role="button" data-toggle="collapse" data-target="#collapseStepTwo"
                   aria-expanded="true">
                <h3>Note</h3>
              </div>


              <div class="col-md-12 p-0 alert-input-popup" *ngIf="alertInputPopup.message !== ''">
                <!-- Error Msg Alert -->
                <div class="alert alert-dismissible alert-danger" *ngIf="alertInputPopup.alertType === alertType.Error">
                  <button type="button" class="close" data-dismiss="alert" aria-label="close"
                          (click)="setAlertMsgToEmpty()">
                    ×
                  </button>
                  <i class="material-icons">warning</i>
                  <p [innerHtml]="alertInputPopup.message"></p>
                </div>
                <!-- Success Msg Alert -->
                <div class="alert alert-success alert-dismissible"
                     *ngIf="alertInputPopup.alertType === alertType.Success">
                  <button type="button" class="close" data-dismiss="alert" aria-label="close"
                          (click)="setAlertMsgToEmpty()">
                    ×
                  </button>
                  <i class="material-icons" aria-hidden="true">check_circle</i>
                  <p [innerHtml]="alertInputPopup.message"></p>
                </div>
              </div>

              <div class="dw-box-content-container collapse show" id="collapseStepTwo" aria-labelledby="headingTwo">
                <div id="noteMainDivCommitment">
                  <form [formGroup]="commitmentNoteForm">
                    <textarea id="noteTextareaForCommitment"
                              formControlName="noteForCommitment" class="form-control noteTextareaForCommitment"
                              *ngIf="noteVisibilityCheck()" style="" name="noteForCommitment"
                              [disabled]="clientId === 0 || clientId === -1"></textarea>
                    <pre id="notePreTagForCommitment" [hidden]="noteVisibilityCheck()">{{noteForCommitment}}</pre>
                  </form>
                  <button *ngIf="noteVisibilityCheck() && clientId !== 0 && clientId !== -1" type="button"
                          class="btn fdl-btn myButton" (click)="saveNoteForCommitment()">
                    Save
                  </button>
                  <p *ngIf="noteVisibilityCheck()">
                    Reminder: Notes are Client Visible.
                  </p>
                </div>

              </div>
            </div>
            <div class="dw-box" *ngIf="isCustomClient && !this.isClientAdmin && !this.isClientExecutive">
              <div class="dw-box-title-container" role="button" data-toggle="collapse" data-target="#collapseStepThree"
                   aria-expanded="true">
                <h3>Client User Assignment</h3>
              </div>
              <div class="dw-box-content-container collapse show" id="collapseStepThree">
                <br />
                <label>The Project is visible to below client users: </label>
                <table class="table table-hover" *ngIf="SelectedCEUsersDetails">
                  <thead>
                    <tr role="row">
                      <th role="columnheader">Name</th>
                      <th role="columnheader">Email</th>
                      <th role="columnheader">Group</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let SelectedCEUser of SelectedCEUsersDetails;trackBy: trackByFn">
                      <td role="cell">{{SelectedCEUser.name}}</td>
                      <td role="cell">{{SelectedCEUser.email}}</td>
                      <td role="cell">{{SelectedCEUser.restrictedUserGroup}}</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <br />
                <div >
                  <button style="background: #f60; margin-bottom: 15px; margin-right: 18px; color: white; font-weight:bold" type="button" *ngIf="!isClientExecutive && !this.isClientAdmin &&
      (((isCCTAdmin && pageTemplateAdmin) || (isCCTAdmin && !pageTemplateAdmin && detailsCommitment.cretedById === userData.id))
       || isAdmin || isRestrictedAdmin || isRm || isSalesExecutive || isAccountManager ||isAccountExecutive || isNewRM)" class="btn fdl-btn btn-primary-orange float-right"
                          data-toggle="modal" data-target="#dashboardEditCommitmentUsers" (click)="openUpdateUsersModal(detailsCommitment)">
                    Edit User Assignment
                  </button>
                </div>
              </div>
            </div>

            <div class="reason-field">
              <!-- <div class="col-md-12   mb-1">
                <div class="checkbox" [ngClass]="{'has-error':!reasonForm.get('isRisk').valid && reasonForm.get('isRisk').errors?.required && (reasonForm.get('isRisk').touched || isSubmitted)}">
                  <input type="checkbox" id="myCheckbox" formControlName="isRisk" value="true" name="isRisk">
                  <label for="myCheckbox"><span>Please check, if commitment is At Risk. </span></label>
                  <div class="has-error">
                    <span *ngIf="!reasonForm.get('isRisk').valid && reasonForm.get('isRisk').errors?.required && (reasonForm.get('isRisk').touched || isSubmitted)"><i class="material-icons">warning</i> Please select the Risk. </span>
                  </div>
                </div>
              </div>

              <div class="col-md-6  mb-2" *ngIf="(reasonForm.controls['isRisk'].value)">
                <div class="filter-section">

                  <label>Risk Reason:</label>
                  <div class="fdl-select full-width p-0">
                    <select class="form-control" formControlName="reason" name="reason">
                      <option value="">Please select</option>
                      <option value="Aggressive Schedule">Aggressive Schedule</option>
                      <option value="Client Requested Project On Hold">Client Requested Project On Hold</option>
                      <option value="Client Temperature is Red">Client Temperature is Red</option>
                      <option value="Federal Regulatory Approval Required">Federal Regulatory Approval Required</option>
                      <option value="GoLive/Delivery Date Changed">GoLive/Delivery Date Changed</option>
                      <option value="Late Requirement/Design">Late Requirement/Design</option>
                      <option value="OnHold By Fiserv">OnHold By Fiserv</option>
                      <option value="Product Integration Delays">Product Integration Delays</option>
                      <option value="Product Quality Issues">Product Quality Issues</option>
                      <option value="Requirement Not Clear">Requirement Not Clear</option>
                      <option value="Resource Availability">Resource Availability</option>
                      <option value="Scope Not Finalized">Scope Not Finalized</option>
                      <option value="other">Other (Please specify)</option>
                    </select>
                    <div class="has-error">
                      <span *ngIf="!reasonForm.get('reason').valid && reasonForm.get('reason').errors?.required && (reasonForm.get('reason').touched || isSubmitted)"><i class="material-icons">warning</i> Please select Reason. </span>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="(reasonForm.controls['isRisk'].value) && (reasonForm.controls['reason'].value =='other')" class="col-md-12 mb-3" [ngClass]="{'has-error':!reasonForm.get('description').valid && reasonForm.get('description').errors?.required && (reasonForm.get('description').touched || isSubmitted)}">
                <label _ngcontent-ccx-c350="" for="description">Description:</label>
                <textarea formControlName="description" name="reason" _ngcontent-ccx-c350="" id="description" formcontrolname="description" class="form-control"></textarea>

                <div class="has-error">
                  <span *ngIf="!reasonForm.get('description').valid && reasonForm.get('description').errors?.required && (reasonForm.get('description').touched || isSubmitted)"><i class="material-icons">warning</i> Please enter the description. </span>
                </div>
              </div> -->


              <div class="fdl-2 button-container">
                <button class="btn fdl-btn btn-secondary-light" id="dCancel" (click)="closeDrawer();">Close</button>
                <button type="button"
                        *ngIf="!isClientExecutive &&
                  (((isCCTAdmin && pageTemplateAdmin) || (isCCTAdmin && !pageTemplateAdmin && detailsCommitment.cretedById === userData.id))
                   || isAdmin || isRestrictedAdmin || isRm || isSalesExecutive || isAccountManager ||isAccountExecutive || isNewRM)"
                        class="btn fdl-btn btn-primary-orange float-right" (click)="openDeleteModal()">
                  Remove
                </button>
                <!-- <div>
                  <button type="button" class="btn fdl-btn btn-primary-orange float-right" (click)="submitRisk()">Submit</button>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="backdrop-space">
      <div class="backdrop"></div>
    </div>
  </div>
  <delete-warning *ngIf="this.detailsCommitment" [componentId]="'deleteWarningModal'"
                  [type]="this.detailsCommitment.commitmentTitle" (okBtnClicked)="deletePageResponse()" [clientDashboard]="true">
  </delete-warning>

  <app-commitment-dashboard-edit-assignusers *ngIf="showClientUserEditModal" (closeModal)="editCommitmentUsersModal()" (submitModal)="editCommitmentUsersModal()"
                                             [commitmentDetails]="selectedForeditUserAssignement"></app-commitment-dashboard-edit-assignusers>
  <div id="drawerDialog" class="d-none">
    <div tabindex="-1" aria-labelledby="addContacts" aria-expanded="true" role="region"
         class="drawer drawer-right dw-xs-12 dw-sm-8 dw-md-5 fold open">
      <div class="drawer-contents commitment-dw review-dw col-xs-12 col-sm-12 col-md-12 ">
        <div class="dw-header">
          <div class="modal-header-container">
            <button type="button" data-dismiss="modal" tabindex="0" autofocus="" aria-label="Close popup"
                    (click)="close()" class="arrow-dw" style="margin-top: 0;">
              <span class="material-icons">
                arrow_back
              </span>
            </button>
            <h4 class="modal-title">
              Review Trail<p>Total {{commitmentReviews.length}} comments.</p>
            </h4>
          </div>
          <div class="right-wrapper d-flex" style="display: flex;">
            <button type="button" data-dismiss="modal" aria-label="Close popup" id="closeDw" class="close"
                    (click)="close()">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
        <div class="dw-body">
          <div class="msg-window-container">
            <div class="card" id="msgWindow">
              <div class="card-content-warpper">
                <div class="card-body" id="msgs">
                  <div class="msg to" *ngFor="let item of commitmentReviews">
                    <div class="avatar-title">
                      <p>{{item.createdByName}} <small>{{item.createdOn | date: 'MM/dd/yyyy HH:mm'}}</small></p>
                      {{item.comments}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="backdrop-space">
      <div class="backdrop"></div>
    </div>
  </div>
  <app-commitment-dashboard-exporting [clientId]="clientId" [summary]="summary"
                                      [commitmentProducts]="commitmentProducts" [commitmentCalendarNumbers]="commitmentCalendarNumbers"
                                      [commitmentCalendarData]="commitmentCalendarData" [cBProductsError]="cBProductsError" [pageFilterForm]="filterPage"
                                      [clientTypeSearch]="clientTypeSearch"
                                      [commitmentCalendar]="commitmentCalendar" [isPdfExport]="isPdfExport"
                                      [typeOfCommitmentCalander]="typeOfCommitmentCalander" [note]="note"
                                      [projectTotalCount]="commitmentAllRowsCount" [nonProjectTotalCount]="commitmentManualRowsCount"
                                      [commitmentAllRows]="commitmentAllRows" [commitmentManualRows]="commitmentManualRows"></app-commitment-dashboard-exporting>
  <app-review-commitment-modal [clientId]="clientId"
                               (submit)="onReviewCommitmentModalSubmit()"></app-review-commitment-modal>
  <app-undo-commitment-modal [clientId]="clientId"
                             (submit)="deleteLatestCommitmentIsReviewed()"></app-undo-commitment-modal>
