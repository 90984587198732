<div [formGroup]="commitmentForm" class="card">
    <div class="card-header">
        <b>Client Details</b>
    </div>
    <div class="card-body">
        <div class="row">
            <!--left side -->
            <div class="col-6">
                <div class="form-group">
                    <label class="col-sm-6 col-form-label asterisk">Client Name</label>
                    <!-- <div class="col">
                        <input formControlName="clientName"  type="text" class="form-control">
                    </div> -->
                    <div class="col">
                        <ng-select formControlName="clientName" [items]="ecrmClients" [virtualScroll]="true"
                            [clearOnBackspace]="false" placeholder="Type to search" bindLabel="clientName"
                            bindValue="clientName">
                            <ng-template ng-option-tmp let-itemTemp="item" let-item$="item$"
                                let-index="index">
                                <span  title="{{itemTemp.clientName}}">
                                    {{itemTemp.clientName}} </span>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>

                <div class="form-group">
                    <label class="col-sm-6 col-form-label asterisk">City and State </label>
                    <div class="col">
                        <input formControlName="address" type="text" class="form-control">
                    </div>
                </div>

                <div class="form-group">
                    <label class="col-sm-6 col-form-label asterisk">Client Total Contract ACV</label>
                    <div class="col">
                        <input formControlName="clientACV" type="text" class="form-control">
                    </div>
                </div>
            </div>

            <!--right side -->
            <div class="col-6">
                <div class="form-group">
                    <label class="col-sm-6 col-form-label asterisk">Client Identifier (DUNS ID)</label>
                    <div class="col">
                        <ng-select formControlName="duns" [items]="ecrmClients" [virtualScroll]="true"
                            [clearOnBackspace]="false" placeholder="Type to search" bindLabel="duns"
                            bindValue="duns">
                            <ng-template ng-option-tmp let-itemTemp="item" let-item$="item$"
                                let-index="index">
                                <span  title="{{itemTemp.duns}}">
                                    {{itemTemp.duns}} </span>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="col has-error">
                        <span *ngIf="!commitmentForm.get('duns').valid && commitmentForm.get('duns').dirty && commitmentForm.get('duns').errors?.maxlength">
                            <i class="material-icons">warning</i>DUNS ID must be less than 10 characters.
                        </span>
                    </div>
                </div>

                <div class="form-group">
                    <label class="col-sm-6 col-form-label asterisk">eCRM Account Number </label>
                    <div class="col">
                        <input formControlName="ecrmAccountNumber" type="text" class="form-control">
                    </div>
                </div>

                <div class="form-group">
                    <label class="col-sm-6 col-form-label asterisk">Client Total Contract TCV</label>
                    <div class="col">
                        <input formControlName="clientTCV" type="text" class="form-control">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<br>