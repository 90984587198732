import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ManualCommitmentBU, ManualCommitmentBUOptions,
  ManualCommitmentYesNo, ManualCommitmentYesNoOptions,
  ManualCommitmentExecLeaders, ManualCommitmentExecLeadersOptions,
  ManualCommitmentFAPOptions, ManualCommitmentObligationOptions,
  ManualCommitmentObligationType, ManualCommitmentPlatformOptions,
  ManualCommitmentProduct, ManualCommitmentStatusCCT,
  ManualCommitmentStatusCCTOptions, ManualCommitmentStatusRM,
  ManualCommitmentStatusRMOptions, ManualCommitmentType, ManualCommitmentTypeOptions,
  ROLES,
  MAX_LENGTHS,
  CurrentLoggedInUser,
  REGEX_PATTERNS
} from 'src/app/core/constants';
import { ProjectsService } from 'src/app/services/projects.service';
declare var $: any;
import * as _ from 'lodash';
import { ManualCommitmentService } from 'src/app/services/manual-commitment.service';
import { EMPTY, Subscription, of } from 'rxjs';
import { IUser } from 'src/app/shared/user';
import { UserService } from 'src/app/services/user.service';
import { IQuery } from 'src/app/shared/query';
import { environment } from 'src/environments/environment';
import { formatDate } from '@angular/common';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-manual-commitment',
  templateUrl: './manual-commitment.component.html',
  styleUrls: ['./manual-commitment.component.css']
})

export class ManualCommitmentComponent implements OnInit, OnDestroy {
  commitmentForm: FormGroup;
  commitmentDetailsModalForm: FormGroup;
  errorMessage: string;
  todaysdate: string;
  currentDeleteId = null;
  currentUser: IUser;
  queryParam: IQuery;
  currentPageNumber: number = 1;
  modelDetail: any;
  currentEditItem = null;

  isEdit = false;
  isView = false;
  isEditModal = false;
  isCCTCoordinator = false;
  isSubmitted = false;
  isNAProduct = true;
  isDisplayRMOnlySection = false;
  isDisplaySLASection = false;
  isDisplayPlatformSection = false;
  isDisplayFAPSection = false;
  isDisplayFAPTypeSection = false;
  isDisplayApproverEmailSection = false;
  isDisplayExplainForCCTStatusSection = false;
  isDisplayCCTOnlySection = true;
  isDisplayConsultantInfoSection = false;
  isDisplayRequestorResponseCCTSection = false;
  isDisplayNoteToRequestorCCTSection = false;
  isDisplayOtherProduct = false;
  isDuplicateProduct = false;
  isRequiredExecEmail = false;

  noteExplainForCCTStatus = '';

  // Dropdown values
  platformOptions = ManualCommitmentPlatformOptions;
  fapOptions = ManualCommitmentFAPOptions;
  commitmentTypeOptions = ManualCommitmentTypeOptions;
  obligationTypeOptions = ManualCommitmentObligationOptions;
  commitmentStatusOptions = ManualCommitmentStatusCCTOptions;
  executiveLeaderNameOptions = ManualCommitmentExecLeadersOptions;
  yesNoOptions = ManualCommitmentYesNoOptions;
  buOptions = ManualCommitmentBUOptions;
  userOptions: any;

  productOptions = [
    {
      value: 'N/A',
      name: 'N/A'
    },
    {
      value: 'Other',
      name: 'Other'
    }];

  coveredServiceOptions = [];
  allCoveredServiceOptions = [];
  allRequestStatusesOptions = [];
  ecrmClientNames = [];
  ecrmClientDUNS = [];
  ecrmClientOptions = [];
  hasError = false;
  successMsg = '';
  subs: Subscription[] = [];
  pageTemplateId = environment.clientCommitmentPageTemplateId;

  get commitmentDetails() {
    return this.commitmentForm.get('commitmentDetails') as FormArray;
  }

  get fsContacts() {
    return this.commitmentForm.get('fsContacts') as FormArray;
  }

  constructor(private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private projectsService: ProjectsService,
    private userService: UserService,
    private manualCommitmentService: ManualCommitmentService) { }

  ngOnInit(): void {
    const user = localStorage.getItem(CurrentLoggedInUser);
    if (user) {
      this.currentUser = JSON.parse(user);
    } else {
      this.currentUser = this.userService.getUserListData();
    }
    this.todaysdate = this.projectsService.getTodaysDate();

    this.initForm();
    this.initTooltip();
    this.initQueryParams();
    this.getDetail();
    this.getReferenceData();
    this.getEcrmProducts();
  }

  private getDetail(): any {
    if (this.router.url.indexOf('edit') !== -1) {
      const canEdit = !!this.getValidRoleForRequestor(this.currentUser.roles);
      if (canEdit) {
        this.isEdit = true;
      } else {
        this.backToList();
      }
    } else if (this.router.url.indexOf('view') !== -1) {
      this.commitmentForm.disable();
      this.isView = true;
    }

    this.route.params.subscribe((params) => {
      if (params.id) {
        this.getModelDetail(params.id);
      } else {
        const sub = this.getEcrmClientsByUser(this.currentUser).subscribe((res: any) => {
          this.ecrmClientOptions = res.body;
        }, (error) => { });
        this.subs.push(sub);
      }
    });
  }

  private initTooltip(): void {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }

  private initQueryParams() {
    this.queryParam = Object.assign({}, {
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize
    });
  }

  private initForm(): any {
    this.commitmentForm = this.fb.group({
      title: [null, Validators.required],
      productName: [null],
      buName: [null],
      fsContacts: this.fb.array([], Validators.required),
      clientDetails: this.fb.group({
        clientId: [null, Validators.required],
        clientName: [null, Validators.required],
        duns: [null, [Validators.required, Validators.maxLength(10)]],
        address: [null, Validators.required],
        ecrmAccountNumber: [null, Validators.required],
        clientACV: [null, Validators.required],
        clientTCV: [null, Validators.required],
      }),
      commitmentDetails: this.fb.array([], Validators.required),
    });

    this.commitmentDetailsModalForm = this.createModalForm();

    this.addContact();
    this.onFormChanges();
  }

  private createContactForm(): any {
    const contact = this.fb.group({
      id: null,
      name: [null, Validators.required],
      email: [null, [Validators.email, Validators.required]],
      role: [null, [Validators.required]],
    });
    contact.controls.name.disable();
    contact.controls.email.disable();
    contact.controls.role.disable();

    return contact;
  }

  private createModalForm(): any {
    const form = this.fb.group({
      rowId: null,
      id: null,
      productId: [null, Validators.required],
      productName: [null, Validators.required],
      otherProductName: [null, Validators.maxLength(50)],
      buId: [null, Validators.required],
      buName: [null, Validators.required],
      platformId: [null],
      platform: [null],
      ecrmOppId: [null],
      ecrmOppTCV: [null],
      ecrmOppACV: [null],
      fapId: [null],
      fap: [null],
      fapType: [null],
      commitmentTypeId: [null],
      commitmentType: [null, Validators.required],
      obligationTypeId: [null],
      obligationType: [null, Validators.required],
      clientRequestedDate: [null],
      clientRequestedDateComment: [null],
      execLeaderName: [null],
      execLeaderEmail: [{ value: null, disabled: true }, [Validators.email, Validators.pattern(REGEX_PATTERNS.Fiserv_Email)]],
      requestStatusId: [null],
      requestStatus: [null, Validators.required],
      requestStatusRMComment: [null],

      isAdditionalInfoRequiredCCT: [ManualCommitmentYesNo.No],
      requestorResponseCCT: [null],
      explainForStatusChangedCCT: [null],
      noteToRequestorCCT: [null],
      approverEmailCCT: [null, [Validators.email, Validators.pattern(REGEX_PATTERNS.Fiserv_Email)]],

      commitmentSLACCT: this.fb.group({
        additionalInfoDateCommitted: [null],
        isClientRequested: [ManualCommitmentYesNo.No],
        isConsultant: [ManualCommitmentYesNo.No],
        consultantCompanyName: [null],
        consultantName: [null],
        coveredService: [null],
        detailsSLA: [null],
        additionalInfoSLA: [null]
      })
    });

    return form;
  }

  private onFormChanges() {
    this.onMainFormChanges();
    this.onModalFormChanges();
  }

  private onModalFormChanges() {
    this.commitmentDetailsModalForm.controls.productName.valueChanges.subscribe(value => {
      this.validateForECRMInfoByProduct(value);
      this.validateDuplicateProduct(value)
      this.setDisableControlModalFormByProduct(value);
    });

    this.commitmentDetailsModalForm.controls.otherProductName.valueChanges.subscribe(value => {
      this.validateProductLength(value)
    });

    this.commitmentDetailsModalForm.controls.commitmentType.valueChanges.subscribe(value => {
      if (!value) return;
      const commitmentType = this.commitmentTypeOptions.find(el => el.value === value);
      const obligationType = this.obligationTypeOptions.find(el => el.value === this.commitmentDetailsModalForm.value.obligationType);
      this.validateBusinessRuleModalForm(commitmentType?.name, obligationType?.name);
    });

    this.commitmentDetailsModalForm.controls.obligationType.valueChanges.subscribe(value => {
      if (!value) return;
      const obligationType = this.obligationTypeOptions.find(el => el.value === value);
      const commitmentType = this.commitmentTypeOptions.find(el => el.value === this.commitmentDetailsModalForm.value.commitmentType);
      this.handleForExecLeader(obligationType.name);
      this.validateBusinessRuleModalForm(commitmentType?.name, obligationType?.name);
    });

    this.commitmentDetailsModalForm.controls.fap.valueChanges.subscribe(value => {
      const item = this.fapOptions.find(el => el.value === value);
      this.handleForFAPType(item?.name);
    });

    this.commitmentDetailsModalForm.controls.execLeaderName.valueChanges.subscribe(value => {
      this.handleForExecLeaderEmail(value);
    });

    this.commitmentDetailsModalForm.controls.requestStatus.valueChanges.subscribe(value => {
      const item = this.commitmentStatusOptions.find(el => el.value === value);
      this.handleForApproverEmail(item?.name);
      this.handleForExplainStatusChangedCCT(item?.name);
      this.handleForExplainStatusChangedCCTNote(item?.name);
    });

    this.commitmentDetailsModalForm.controls.isAdditionalInfoRequiredCCT.valueChanges.subscribe(value => {
      this.handleForRequestorAdditionalInfoCCT(value);
    });

    this.commitmentDetailsModalForm
      .get('commitmentSLACCT')
      .get('isConsultant').valueChanges.subscribe(value => {
        this.handleForSLAConsultant(value);
      });
  }

  private onMainFormChanges() {
    this.commitmentForm.controls.productName.valueChanges.subscribe(val => {
      const productName = this.getSingleSelectedName(this.productOptions, val);
      this.onSelectProductChanged(productName);
    });

    this.commitmentForm.controls.buName.valueChanges.subscribe(values => {
      const names = this.getMultipleSelectedNames(this.buOptions, values);
      this.onSelectBUChanged(names);
      if (names) {
        this.coveredServiceOptions = this.allCoveredServiceOptions.filter(item => names.includes(item.businessUnit));
      }
    });
  }


  private handleForRequestorAdditionalInfoCCT(value: any) {
    if (value && value.includes(ManualCommitmentYesNo.Yes)) {
      this.isDisplayRequestorResponseCCTSection = true;
      this.setValidator(this.commitmentDetailsModalForm.controls.requestorResponseCCT, Validators.required);
    } else {
      this.isDisplayRequestorResponseCCTSection = false;
      this.clearValidator(this.commitmentDetailsModalForm.controls.requestorResponseCCT);
    }
  }

  private handleForSLAConsultant(value: any) {
    if (value && value.includes(ManualCommitmentYesNo.Yes)) {
      this.isDisplayConsultantInfoSection = true;
      this.setValidator(this.commitmentDetailsModalForm.controls.commitmentSLACCT.get('consultantCompanyName'), Validators.required);
      this.setValidator(this.commitmentDetailsModalForm.controls.commitmentSLACCT.get('consultantName'), Validators.required);
    } else {
      this.isDisplayConsultantInfoSection = false;
      this.clearValidator(this.commitmentDetailsModalForm.controls.commitmentSLACCT.get('consultantCompanyName'));
      this.clearValidator(this.commitmentDetailsModalForm.controls.commitmentSLACCT.get('consultantName'));
    }
  }

  private handleForExecLeader(value: any) {
    if (value && value.includes(ManualCommitmentObligationType.ExecutiveSponsoredCommitment)) {
      this.setValidator(this.commitmentDetailsModalForm.controls.execLeaderName, Validators.required);
      this.setValidator(this.commitmentDetailsModalForm.controls.execLeaderEmail, Validators.compose([Validators.required, Validators.email, Validators.pattern(REGEX_PATTERNS.Fiserv_Email)]));
    } else {
      this.clearValidator(this.commitmentDetailsModalForm.controls.execLeaderName);
      this.clearValidator(this.commitmentDetailsModalForm.controls.execLeaderEmail);
      this.setValidator(this.commitmentDetailsModalForm.controls.execLeaderEmail, Validators.compose([Validators.required, Validators.email, Validators.pattern(REGEX_PATTERNS.Fiserv_Email)]));
    }
  }

  private handleForExecLeaderEmail(value: any) {
    if (value !== ManualCommitmentExecLeaders.Other) {
      this.clearValidator(this.commitmentDetailsModalForm.controls.execLeaderEmail);
      this.isRequiredExecEmail = false;
      const item = this.executiveLeaderNameOptions.find(el => el.value === value);
      this.commitmentDetailsModalForm.patchValue({
        execLeaderEmail: item?.email
      }, { emitEvent: false });
      this.commitmentDetailsModalForm.controls.execLeaderEmail.disable({ emitEvent: false });
    } else {
      this.isRequiredExecEmail = true;
      this.commitmentDetailsModalForm.controls.execLeaderEmail.enable({ emitEvent: false });
      this.setValidator(this.commitmentDetailsModalForm.controls.execLeaderEmail, Validators.compose([Validators.required, Validators.email, Validators.pattern(REGEX_PATTERNS.Fiserv_Email)]));
      this.commitmentDetailsModalForm.patchValue({
        execLeaderEmail: ''
      }, { emitEvent: false });
    }
  }

  private handleForFAPType(value: any) {
    this.isDisplayFAPTypeSection = !!value;
    if (this.isDisplayFAPTypeSection) {
      this.setValidator(this.commitmentDetailsModalForm.controls.fapType, Validators.required);
    } else {
      this.clearValidator(this.commitmentDetailsModalForm.controls.fapType);
    }
  }

  private handleForExplainStatusChangedCCT(value: any) {
    if (value && (value.includes(ManualCommitmentStatusCCT.ApprovedWithChanges)
      || value.includes(ManualCommitmentStatusCCT.NotApproved)
      || value.includes(ManualCommitmentStatusCCT.Canceled)
      || value.includes(ManualCommitmentStatusCCT.OnHold))) {
      this.isDisplayExplainForCCTStatusSection = true;
      this.setValidator(this.commitmentDetailsModalForm.controls.explainForStatusChangedCCT, Validators.required);
    } else {
      this.isDisplayExplainForCCTStatusSection = false;
      this.clearValidator(this.commitmentDetailsModalForm.controls.explainForStatusChangedCCT);
    }
  }

  private handleForExplainStatusChangedCCTNote(value: any) {
    if (!value) return;

    if (value.includes(ManualCommitmentStatusCCT.ApprovedWithChanges)) {
      this.noteExplainForCCTStatus = 'Please explain how the approved commitment with changes differs from the original commitment request.';
    } else if (value.includes(ManualCommitmentStatusCCT.NotApproved)
      || value.includes(ManualCommitmentStatusCCT.Canceled)
      || value.includes(ManualCommitmentStatusCCT.OnHold)) {
      this.noteExplainForCCTStatus = 'Please explain why the commitment was not approved, placed on hold, or canceled. Indicate who made this decision.';
    }
  }

  private handleForApproverEmail(value: any) {
    this.isDisplayApproverEmailSection = value && (value.includes(ManualCommitmentStatusCCT.ApprovedNoChanges)
      || value.includes(ManualCommitmentStatusCCT.ApprovedWithChanges));
    if (this.isDisplayApproverEmailSection) {
      this.setValidator(this.commitmentDetailsModalForm.controls.approverEmailCCT, [Validators.required, Validators.email]);
    } else {
      this.clearValidator(this.commitmentDetailsModalForm.controls.approverEmailCCT);
    }
  }

  private onSelectProductChanged(productName: string): void {
    const buNames = this.getMultipleSelectedNames(this.buOptions, this.commitmentForm.value.buName);
    this.onSelectProductOrBUChanged({ productName, buName: buNames });
  }

  private onSelectBUChanged(buName: string): void {
    this.setDisplaySectionByBU(buName);
    const productName = this.getSingleSelectedName(this.productOptions, this.commitmentForm.value.productName);
    this.onSelectProductOrBUChanged({ productName, buName });
  }

  private onSelectProductOrBUChanged(value: any): any {
    this.handleForPlatform(value);
    this.displayCommitentDetailModal(value);
  }

  private handleForPlatform(value: any) {
    // Only Display the Platform drop down when the Business Unit is Card Services and Product selected is anything other than NA.
    this.isDisplayPlatformSection = (value.buName?.includes(ManualCommitmentBU.CardServices) && value.productName !== ManualCommitmentProduct.NA);
    if (this.isDisplayPlatformSection) {
      this.setValidator(this.commitmentDetailsModalForm.controls.platform, Validators.required);
    } else {
      this.clearValidator(this.commitmentDetailsModalForm.controls.platform);
    }
  }

  private displayCommitentDetailModal(value: any): any {
    if ((value.buName && value.buName.length > 0) && value.productName) {
      this.commitmentDetailsModalForm.patchValue({
        productId: this.getMainFormProductId(),
        productName: value.productName,
        buId: this.getBUIds(),
        buName: value.buName
      });
      $('#productModal').modal('show');
    }
  }

  private getMainFormProductId(): any {
    return this.commitmentForm.controls.productName.value;
  }

  private getBUIds(): any {
    return this.commitmentForm.controls.buName.value;
  }

  private validateForECRMInfoByProduct(productName: string): any {
    if (productName === ManualCommitmentProduct.NA) {
      this.isNAProduct = true;
      this.clearValidator(this.commitmentDetailsModalForm.controls.ecrmOppId);
      this.clearValidator(this.commitmentDetailsModalForm.controls.ecrmOppTCV);
      this.clearValidator(this.commitmentDetailsModalForm.controls.ecrmOppACV);
    } else {
      this.isNAProduct = false;
      this.setValidator(this.commitmentDetailsModalForm.controls.ecrmOppId, Validators.required);
      this.setValidator(this.commitmentDetailsModalForm.controls.ecrmOppTCV, Validators.required);
      this.setValidator(this.commitmentDetailsModalForm.controls.ecrmOppACV, Validators.required);
    }

    if (productName === ManualCommitmentProduct.Other) {
      this.isDisplayOtherProduct = true;
      this.setValidator(this.commitmentDetailsModalForm.controls.otherProductName, Validators.required);
    } else {
      this.isDisplayOtherProduct = false;
      this.clearValidator(this.commitmentDetailsModalForm.controls.otherProductName);
    }
  }

  private validateDuplicateProduct(productName: string): any {
    if (this.isEditModal) return;

    this.errorMessage = '';
    this.isDuplicateProduct = false;
    const product = this.commitmentDetails.getRawValue().find(item => item.productId !== ManualCommitmentProduct.Other && item.productName === productName);
    if (product) {
      this.isDuplicateProduct = true;
      this.errorMessage = `There was a commitment with the product [${productName}] in the list. Please try again.`;
      this.commitmentDetailsModalForm.disable({ emitEvent: false });
    }
  }

  private validateProductLength(otherProductName: string): any {
    if (!otherProductName) return;

    this.errorMessage = '';
    this.isDuplicateProduct = false;
    if (otherProductName.length > 50) {
      this.errorMessage = `Product name must be less than 50 characters.`;
    }
  }

  private setValidator(control: AbstractControl, validator: ValidatorFn | ValidatorFn[]): void {
    control.setValidators(validator);
    control.updateValueAndValidity();
  }

  private clearValidator(control: AbstractControl): void {
    control.clearValidators();
    control.updateValueAndValidity();
  }

  private validateBusinessRuleModalForm(commitmentTypeValue, obligationTypeValue): any {
    this.setDefaultValuesForModalFormByCommitmentType(commitmentTypeValue);
    this.setDisplaySectionByCommitmentType(commitmentTypeValue);
    this.checkCommitmentModalRules(commitmentTypeValue, obligationTypeValue);
  }

  private setDisplaySectionByCommitmentType(commitmentTypeValue: any) {
    if (!commitmentTypeValue) return;

    // If the Commitment Type is SLA, display SLA section and Client Requested Commitment Date should be grayed out
    if (commitmentTypeValue.includes(ManualCommitmentType.SLA)) {
      this.commitmentDetailsModalForm.controls.clientRequestedDate.disable({ emitEvent: false });
      this.commitmentDetailsModalForm.controls.clientRequestedDateComment.disable({ emitEvent: false });
      this.isDisplaySLASection = true;
      this.setValidator(this.commitmentDetailsModalForm.controls.commitmentSLACCT.get('detailsSLA'), Validators.required);
    } else {
      this.commitmentDetailsModalForm.controls.clientRequestedDate.enable({ emitEvent: false });
      this.commitmentDetailsModalForm.controls.clientRequestedDateComment.enable({ emitEvent: false });
      this.isDisplaySLASection = false;
      this.clearValidator(this.commitmentDetailsModalForm.controls.commitmentSLACCT.get('detailsSLA'));
    }

    // Only display RMOnly section if the Commitment Type is RM Follow Up Only.
    this.isDisplayRMOnlySection = (commitmentTypeValue.includes(ManualCommitmentType.RelationshipManagerFollowUpOnly));
    if (this.isDisplayRMOnlySection) {
      this.commitmentStatusOptions = this.allRequestStatusesOptions
        .filter(el => ManualCommitmentStatusRMOptions.some(item => item.name.includes(el.name)));
      this.clearValidator(this.commitmentDetailsModalForm.controls.requestorResponseCCT);
    } else {
      this.handleForRequestorAdditionalInfoCCT(this.commitmentDetailsModalForm.value.isAdditionalInfoRequiredCCT);
      this.commitmentStatusOptions = this.allRequestStatusesOptions
        .filter(el => ManualCommitmentStatusCCTOptions.some(item => item.name.includes(el.name)));
    }
  }

  private setDisplaySectionByBU(buName): void {
    // Only display the Fiserv Account Processor (FAP) drop down when the Business Unit Selected is Core Account Processing Solutions (CAPS).
    this.isDisplayFAPSection = buName.includes(ManualCommitmentBU.CoreAccountProcessingSolutions);
  }

  private checkCommitmentModalRules(commitmentType: string, obligationType: string) {
    if (!commitmentType) return;

    this.errorMessage = '';

    if (commitmentType.includes(ManualCommitmentType.NewProduct)
      || commitmentType.includes(ManualCommitmentType.CustomFeatureEnhancement)
      || commitmentType.includes(ManualCommitmentType.ImplementationLeadTime)) {
      if (this.commitmentDetailsModalForm.controls.productName.value === ManualCommitmentProduct.NA) {
        this.errorMessage = 'If New Product/ Product Feature, Custom Feature/ Enhancement, or Implementation Lead Time is selected, the Product field cannot be NA';
        return;
      }
    }

    if (commitmentType.includes(ManualCommitmentType.ImplementationLeadTime)) {
      if (this.commitmentDetailsModalForm.controls.productName.value === ManualCommitmentProduct.NA) {
        this.errorMessage = 'When a Commitment Type of Implementation Lead Time is selected, the Product cannot be N/A';
        return;
      }
    }

    if (commitmentType.includes(ManualCommitmentType.SLA)) {
      if (obligationType
        && obligationType !== ManualCommitmentObligationType.ContractualWCredits
        && obligationType !== ManualCommitmentObligationType.ContractualWoCredits
      ) {
        this.errorMessage = 'If Commitment Type of SLA is selected, the Obligation Types must be selected are: Contractual with Credits or Contractual w/o Credits.';
        return;
      }
    }

    if (commitmentType.includes(ManualCommitmentType.SLA)
      || commitmentType.includes(ManualCommitmentType.ImplementationLeadTime)
      || commitmentType.includes(ManualCommitmentType.ResourceAllocation)) {
      if (obligationType === ManualCommitmentObligationType.VerbalCommitment) {
        this.errorMessage = 'An Obligation Type of Verbal Commitment cannot be selected when the Commitment Type is SLA, Implementation Lead Time, or Resource Allocation.';
        return;
      }
    }
  }

  private setDefaultValuesForModalFormByCommitmentType(commitmentType: string) {
    if (!commitmentType) return;

    // 1. When Commitment Type is Service Level Agreement default the Product to N/A.
    this.setDefaultProductValue(commitmentType);

    // When New Product/ Product Feature, Custom Feature/Enhancement, SLA is selected, the Current Commitment Status field must default to: Pending Review
    // When the Commitment Type is RM Follow Up Only, default the Current Commitment Request Status to “InProgress”
    // Else set it is New request
    this.setDefaultCommitmentStatus(commitmentType);

    // If the Commitment Type of Relationship Manager Follow Up Only is selected, the Obligation Type must default to Relationship Manager Commitment.
    this.setDefaultObligationType(commitmentType);
  }

  private setDefaultObligationType(commitmentType: string) {
    if (commitmentType.includes(ManualCommitmentType.RelationshipManagerFollowUpOnly)) {
      this.commitmentDetailsModalForm.patchValue({
        obligationType: this.getObligationTypeIdByName(ManualCommitmentObligationType.RelationshipManagerCommitment),
      }, { emitEvent: false });
      this.commitmentDetailsModalForm.controls.obligationType.disable({ emitEvent: false });
    } else {
      this.commitmentDetailsModalForm.controls.obligationType.enable({ emitEvent: false });
    }
  }

  private setDefaultCommitmentStatus(commitmentType: string) {
    if (commitmentType.includes(ManualCommitmentType.NewProduct)
      || commitmentType.includes(ManualCommitmentType.CustomFeatureEnhancement)
      || commitmentType.includes(ManualCommitmentType.SLA)) {
      this.commitmentDetailsModalForm.patchValue({
        requestStatus: this.getStatusIdByName(ManualCommitmentStatusCCT.UnderReview),
      });
    } else if (commitmentType.includes(ManualCommitmentType.RelationshipManagerFollowUpOnly)) {
      this.commitmentDetailsModalForm.patchValue({
        requestStatus: this.getStatusIdByName(ManualCommitmentStatusRM.InProgress),
      });
    } else {
      this.commitmentDetailsModalForm.patchValue({
        requestStatus: this.getStatusIdByName(ManualCommitmentStatusCCT.NewRequest),
      });
    }
  }

  private getStatusIdByName(name): any {
    const item = this.allRequestStatusesOptions.find(el => el.name.includes(name));
    return item?.value;
  }

  private getObligationTypeIdByName(name): any {
    const item = this.obligationTypeOptions.find(el => el.name.includes(name));
    return item?.value;
  }

  private setDefaultProductValue(commitmentType: string) {
    if (commitmentType.includes(ManualCommitmentType.SLA)) {
      this.commitmentDetailsModalForm.patchValue({
        productName: ManualCommitmentProduct.NA,
        otherProductName: null,
      });
    } else if (commitmentType.includes(ManualCommitmentType.CustomFeatureEnhancement)) {
      this.commitmentDetailsModalForm.patchValue({
        productName: ManualCommitmentProduct.Other,
      });
    } else {
      const productName = this.isEditModal
        ? this.currentEditItem.productName
        : this.getSingleSelectedName(this.productOptions, this.commitmentForm.value.productName);
      this.commitmentDetailsModalForm.patchValue({
        productName: productName,
        productId: this.isEditModal ? this.currentEditItem.productId : this.getMainFormProductId(),
        otherProductName: this.isEditModal ? this.currentEditItem.otherProductName : this.commitmentDetailsModalForm.controls.otherProductName.value,
      });
    }
  }

  private setDisableControlModalFormByProduct(productName: string) {
    if (!this.commitmentDetailsModalForm) return false;

    this.commitmentDetailsModalForm.controls.productName.disable({ emitEvent: false });
    this.commitmentDetailsModalForm.controls.buName.disable({ emitEvent: false });
    if (productName === ManualCommitmentProduct.Other) {
      this.commitmentDetailsModalForm.controls.otherProductName.enable({ emitEvent: false });
    } else {
      this.commitmentDetailsModalForm.controls.otherProductName.disable({ emitEvent: false });
    }
  }
  
  private getReferenceData() {
    const sub = this.manualCommitmentService.getReferenceData()
      .subscribe((res: any) => {
        console.log(res)
        this.commitmentTypeOptions = this.convertReferenceData(res.body.commitmentTypes);
        this.obligationTypeOptions = this.convertReferenceData(res.body.obligationTypes);
        this.allCoveredServiceOptions = res.body.coveredServices.map(el => { return { value: el.id, name: el.title, tooltip: el.title, businessUnit: el.businessUnit } });
        this.platformOptions = this.convertReferenceData(res.body.platforms);
        this.allRequestStatusesOptions = this.convertReferenceData(res.body.statuses);
        this.fapOptions = this.convertReferenceData(res.body.faPs);
        this.buOptions = this.convertReferenceData(res.body.businessUnits);
      },
        (error) => { console.log(error) });

    this.subs.push(sub);
  }

  private convertReferenceData(input: Array<any> | []) {
    const res = input.map(el => { return { value: el.id, name: el.title, tooltip: el.title } });
    return res;
  }

  private getEcrmProducts(): any {
    const sub = this.manualCommitmentService.getECRMProducts().subscribe((res: any) => {
      const prods = this.convertReferenceData(res.body);
      this.productOptions = [...this.productOptions, ...prods];
    }, (error) => {

    });
    this.subs.push(sub);
  }

  private getEcrmClientsByUser(user: any): any {
    const param = {
      roleTitle: this.getValidRoleForRequestor(user.roles),
      pageTemplateId: this.pageTemplateId
    };
    return this.manualCommitmentService.getECRMClientsByUser(+user.id, param);
  }

  /*
    PUBLIC METHOD
  */

  public formatDate(value, format = 'yyyy-MM-dd'): any {
    if (!value) return null;

    const result = formatDate(value, format, 'en-US');
    return result;
  }

  public getMultipleSelectedNames(options: any, selectedValues: any) {
    if (!selectedValues) return '';

    const items = options.filter(el => !!selectedValues.find(val => el.value === +val));
    const names = items.map((list) => list.name).join(", ");
    return names;
  }

  public getSingleSelectedName(options: any, selectedValue: any) {
    if (!selectedValue) return '';

    const item = options.find(el => el.value === selectedValue);
    return item?.name;
  }

  public getProductNameToDisplay(control: any): string {
    if (!control || !control.productName.value) return null;
    const productValue = control.productName.value;
    if (productValue === ManualCommitmentProduct.Other) return control.otherProductName.value;
    if (productValue === ManualCommitmentProduct.NA) return productValue;

    return productValue;
  }

  public addContact(): void {
    const contact = this.createContactForm();
    this.fsContacts.push(contact);
    this.setContact(this.currentUser);
  }

  private setContact(user) {
    const dataArray = {
      id: user.id,
      name: user.name,
      email: user.email,
      role: this.getValidRoleForRequestor(user.roles)
    };

    this.commitmentForm.controls.fsContacts.patchValue([dataArray]);
  }

  public removeContact(index): void {
    this.fsContacts.removeAt(index);
  }

  
  private generateRowId(): any {
    const rowId = Math.random().toString(36).slice(2, 7);
    return rowId;
  }

  private addCommitmentDetails() {
    const buIds = this.commitmentForm.value.buName;
    buIds.forEach(buId => {
      const rowId = this.generateRowId();
      const buName = this.buOptions.find(el => el.value === buId)?.name;
      this.commitmentDetailsModalForm.patchValue({ rowId, buId, buName });

      let clonedForm = _.cloneDeep(this.commitmentDetailsModalForm);

      // Handle for particular BU
      if (!this.isDisplayFAPSection) {
        clonedForm.patchValue({ fap: null, fapType: null });
        this.clearValidator(clonedForm.controls.fapType);
      }
      if (!this.isDisplayPlatformSection) {
        clonedForm.patchValue({ platform: null });
        this.clearValidator(clonedForm.controls.platform);
      }
      this.commitmentDetails.push(clonedForm);
    });
  }

  private updateCommitmentDetails(rowId, value) {
    const index = this.commitmentDetails.controls.findIndex(x => x.value.rowId === rowId);
    const itemUpdated = this.commitmentDetails.controls[index];
    itemUpdated.patchValue(value);
  }

  public onEditItem(commitmentItem: FormGroup): any {
    this.isEditModal = true;
    this.currentEditItem = commitmentItem.getRawValue();
    this.commitmentDetailsModalForm.patchValue(this.currentEditItem);

    const names = this.getMultipleSelectedNames(this.buOptions, [this.currentEditItem.buId]);
    this.coveredServiceOptions = this.allCoveredServiceOptions.filter(item => names.includes(item.businessUnit));
    this.handleForPlatform(this.currentEditItem);
    this.setDisplaySectionByBU(this.currentEditItem.buName);
    $('#productModal').modal('show');
  }

  public onDeleteItem(rowId): any {
    this.currentDeleteId = rowId;
    $('#deleteConfirmModal').modal('show');
  }

  public onCloseDeleteModal(): any {
    this.currentDeleteId = null;
    $('#deleteConfirmModal').modal('hide');
  }

  public deleteCommitmentDetail(): void {
    const result = this.commitmentDetails.value.findIndex(item => item.rowId === this.currentDeleteId);
    if (result !== -1) this.commitmentDetails.removeAt(result);
    this.onCloseDeleteModal();
  }

  public getModalFormCountSelectedLabel(controlName: string) {
    const count = this.commitmentDetailsModalForm.get(controlName).value.length;
    return `${count} item${count > 1 ? 's' : ''}`;
  }

  public getMainFormCountSelectedLabel(controlName: string) {
    const count = this.commitmentForm.get(controlName).value?.length;
    return `${count} item${count > 1 ? 's' : ''}`;
  }

  public getCommitmentStatusOptions(): any {
    const result = this.commitmentStatusOptions.filter(item => item.name);
    return result;
  }

  public onSaveModalChanges(): void {
    const modalRawValue = this.commitmentDetailsModalForm.getRawValue();
    if (this.isEditModal) {
      const rowId = this.commitmentDetailsModalForm.value.rowId;
      this.updateCommitmentDetails(rowId, modalRawValue);
    } else {
      this.addCommitmentDetails();
    }

    this.closeModal();
  }

  public onCloseModal(): any {
    this.closeModal();
  }

  private closeModal(): void {
    $('#productModal').modal('hide');
    this.resetModalForm();
    this.isEditModal = false;
  }

  private resetModalForm() {
    this.commitmentDetailsModalForm.enable({ emitEvent: false });
    this.commitmentDetailsModalForm.reset();
    this.commitmentForm.patchValue({
      buName: null,
      productName: null
    }, { emitEvent: false });
    this.isDisplaySLASection = false;
    this.isDisplayRMOnlySection = false;
  }

  public getCommitmentTypeTooltip(name: string): any {
    const matchedItem = ManualCommitmentTypeOptions.find(el => el.name.includes(name));
    const tooltip = matchedItem ? matchedItem.tooltip : name;

    return tooltip;
  }

  public getObligationTypeTooltip(name: string): any {
    const matchedItem = ManualCommitmentObligationOptions.find(el => el.name.includes(name));
    const tooltip = matchedItem ? matchedItem.tooltip : name;

    return tooltip;
  }

  public contactNameChanged(selectedUser, index): any {
    if (selectedUser) {
      const dataArray = this.commitmentForm.value.fsContacts[index];
      dataArray.email = selectedUser.email;
      dataArray.role = this.getValidRoleForRequestor(selectedUser.userRoles);
      this.commitmentForm.controls.fsContacts.patchValue([dataArray]);
    } else {
      this.onClearContact(index);
    }

  }

  public contactEmailChanged(selectedUser, index): any {
    if (selectedUser) {
      const dataArray = this.commitmentForm.value.fsContacts[index];
      dataArray.name = selectedUser.name;
      dataArray.role = this.getValidRoleForRequestor(selectedUser.userRoles);
      this.commitmentForm.controls.fsContacts.patchValue([dataArray]);
    } else {
      this.onClearContact(index);
    }

  }

  private onClearContact(index): any {
    const dataArray = this.commitmentForm.value.fsContacts[index];
    dataArray.name = dataArray.email = dataArray.role = null;
    this.commitmentForm.controls.fsContacts.patchValue([dataArray]);
  }

  private getValidRoleForRequestor(userRoles: any[]): any {
    const userRole = userRoles.find(el => el.roleTitle === ROLES.Account_Manager
      || el.roleTitle === ROLES.Relationship_Manager
      || el.roleTitle === ROLES.Sales_Executive
      || el.roleTitle === ROLES.CCT_Admin
      || el.roleTitle === ROLES.Admin
      || el.roleTitle === ROLES.Account_Executive);

    return userRole ? userRole.roleTitle : '';
  }

  private getModelDetail(id: string): any {
    const sub = this.manualCommitmentService.get(id)
      .pipe(
        mergeMap((detail: any) => {
          this.modelDetail = detail;
          const commitmentForm = {
            id: this.modelDetail.id,
            title: this.modelDetail.title,
            clientDetails: {
              clientId: this.modelDetail.ecrmClientId,
              clientName: this.modelDetail.ecrmClientName,
              duns: this.modelDetail.ecrmClientDuns,
              address: this.modelDetail.ecrmClientAddress,
              ecrmAccountNumber: this.modelDetail.ecrmClientAccountNumber,
              clientACV: this.modelDetail.ecrmClientACV,
              clientTCV: this.modelDetail.ecrmClientTCV,
            }
          }
          this.commitmentForm.patchValue(commitmentForm);
          const contact = {
            id: this.modelDetail.requestor.id,
            name: this.modelDetail.requestor.name,
            email: this.modelDetail.requestor.email,
            roles: this.modelDetail.requestor.userRoles.map(userRole => ({ roleTitle: userRole.role.title }))
          }
          this.setContact(contact);
          this.modelDetail.products.forEach(el => {
            const item = {
              id: el.id,
              rowId: this.generateRowId(),
              productId: el.productId,
              productName: el.productId === ManualCommitmentProduct.Other ? ManualCommitmentProduct.Other : el.productName,
              otherProductName: el.productId === ManualCommitmentProduct.Other ? el.productName : null,
              buId: el.businessUnit.id,
              buName: el.businessUnit.title,
              platformId: el.platforms?.split(',').map(Number),
              platform: el.platforms?.split(',').map(Number),
              ecrmOppId: el.ecrmOpportunityId,
              ecrmOppTCV: el.ecrmOpportunityTCV,
              ecrmOppACV: el.ecrmOpportunityACV,
              fapId: el.fap?.id,
              fap: el.fap?.id,
              fapType: el.fapType,
              commitmentTypeId: el.commitmentType.id,
              commitmentType: el.commitmentType.id,
              obligationTypeId: el.obligationType.id,
              obligationType: el.obligationType.id,
              clientRequestedDate: el.clientRequestedDate ? this.formatDate(el.clientRequestedDate) : null,
              clientRequestedDateComment: el.clientRequestedDateComment,
              execLeaderName: el.execLeaderName,
              execLeaderEmail: el.execLeaderEmail,
              requestStatusId: el.status?.id,
              requestStatus: el.status?.id,
              requestStatusRMComment: el.requestStatusRMComment,
              isAdditionalInfoRequiredCCT: el.isAdditionalInfoRequiredCCT ? ManualCommitmentYesNo.Yes : ManualCommitmentYesNo.No,
              requestorResponseCCT: el.requestorResponseCCT,
              explainForStatusChangedCCT: el.explainForStatusChangedCCT,
              noteToRequestorCCT: el.noteToRequestorCCT,
              approverEmailCCT: el.approverEmailCCT,
              commitmentSLACCT: {
                additionalInfoDateCommitted: el.additionalInfoDateCommittedSLA,
                isClientRequested: el.isClientRequestedSLA ? ManualCommitmentYesNo.Yes : ManualCommitmentYesNo.No,
                isConsultant: el.isConsultantSLA ? ManualCommitmentYesNo.Yes : ManualCommitmentYesNo.No,
                consultantCompanyName: el.consultantCompanyNameSLA,
                consultantName: el.consultantNameSLA,
                coveredService: el.coveredServiceSLA?.id,
                detailsSLA: el.detailsSLA,
                additionalInfoSLA: el.additionalInfoSLA
              }
            };
            const clonedModalForm = this.createModalForm();
            clonedModalForm.patchValue(item);
            this.commitmentDetails.push(clonedModalForm);
          })
          return of(contact);
        }),
        mergeMap((requestor: any) => {
          return this.getEcrmClientsByUser(requestor);
        }))
      .subscribe((res: any) => {
        this.ecrmClientOptions = res.body;
      });

    this.subs.push(sub);
  };

  public submit(): void {
    this.isSubmitted = true;
    let model = this.convertFormToModel(this.commitmentForm.getRawValue());
    if (this.isEdit) {
      model.id = this.modelDetail.id;
      this.update(model);
    } else {
      this.create(model);
    }
  }

  private create(model: any): any {
    this.hasError = false;
    const sub = this.manualCommitmentService.create(model).subscribe(res => {
      this.notify('Commitment has been created successfully!');
      setTimeout(() => {
        this.backToList();
      }, 2000);
    }, (error) => {
      this.hasError = true;
      this.notify();
    });
    this.subs.push(sub);
  }

  private update(model: any, id?: string): any {
    this.hasError = false;
    const sub = this.manualCommitmentService.update(model.id, model).subscribe(res => {
      this.notify('Commitment has been updated successfully!');
      setTimeout(() => {
        this.backToList();
      }, 2000);
    }, (error) => {
      this.hasError = true;
      this.notify();
    });
    this.subs.push(sub);
  }

  private convertFormToModel(formData: any): any {
    const requestor = formData.fsContacts[0];
    const clientDetails = formData.clientDetails;
    const model = {
      title: formData.title,
      requestorId: +requestor.id,
      requestorName: requestor.name,
      requestorEmail: requestor.email,
      eCRMClientId: clientDetails.clientId,
      eCRMClientName: clientDetails.clientName,
      eCRMClientDuns: clientDetails.duns,
      eCRMClientAddress: clientDetails.address,
      eCRMClientAccountNumber: clientDetails.ecrmAccountNumber,
      eCRMClientACV: clientDetails.clientACV,
      eCRMClientTCV: clientDetails.clientTCV,
      products: this.convertFormModalToModels(this.commitmentDetails.getRawValue())
    }

    return model;
  }

  private convertFormModalToModels(commitmentDetails: any) {
    let models = [];
    commitmentDetails.forEach(el => {
      const model = {
        id: el.id,
        productId: el.productId ? el.productId : null,
        productName: el.productName !== ManualCommitmentProduct.Other ? el.productName : el.otherProductName,
        bus: el.buId,
        platforms: el.platform?.join(','),
        eCRMOpportunityId: el.ecrmOppId,
        eCRMOpportunityACV: el.ecrmOppACV,
        eCRMOpportunityTCV: el.ecrmOppTCV,
        fapId: el.fap,
        fapType: el.fapType,
        commitmentTypeId: el.commitmentType,
        obligationTypeId: el.obligationType,
        statusId: el.requestStatus,
        clientRequestedDate: el.clientRequestedDate,
        clientRequestedDateComment: el.clientRequestedDateComment,
        execLeaderName: el.execLeaderName,
        execLeaderEmail: el.execLeaderEmail,
        requestStatusRMComment: el.requestStatusRMComment,
        isAdditionalInfoRequiredCCT: this.convertStringValueToBoolean(el.isAdditionalInfoRequiredCCT),
        requestorResponseCCT: el.requestorResponseCCT,
        explainForStatusChangedCCT: el.explainForStatusChangedCCT,
        noteToRequestorCCT: el.noteToRequestorCCT,
        approverEmailCCT: el.approverEmailCCT,
        additionalInfoDateCommittedSLA: el.commitmentSLACCT.additionalInfoDateCommitted,
        additionalInfoSLA: el.commitmentSLACCT.additionalInfoSLA,
        consultantCompanyNameSLA: el.commitmentSLACCT.consultantCompanyName,
        consultantNameSLA: el.commitmentSLACCT.consultantName,
        coveredServiceSLAId: el.commitmentSLACCT.coveredService,
        detailsSLA: el.commitmentSLACCT.detailsSLA,
        isClientRequestedSLA: this.convertStringValueToBoolean(el.commitmentSLACCT.isClientRequested),
        isConsultantSLA: this.convertStringValueToBoolean(el.commitmentSLACCT.isConsultant),
      };

      models.push(model);
    });

    return models;
  }

  private convertStringValueToBoolean(input): any {
    const result = !!input && input === ManualCommitmentYesNo.Yes ? true : false;
    return result;
  }

  public backToList(): void {
    this.router.navigate(['manual-commitment/list']);
  }

  private notify(msg = '') {
    this.successMsg = msg;
    var element = document.getElementById("notify");
    element.className = "show";
    setTimeout(function () { element.className = element.className.replace("show", ""); }, 3000);
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
