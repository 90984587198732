import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-manual-commitment-client-details',
  templateUrl: './manual-commitment-client-details.component.html',
  styleUrls: ['./manual-commitment-client-details.component.css']
})
export class ManualCommitmentClientDetailsComponent implements OnInit {

  @Input() formGroupName: string;
  @Input() ecrmClients: [];
  commitmentForm: FormGroup;

  constructor(private rootFormGroup: FormGroupDirective) { }

  ngOnInit(): void {
    this.commitmentForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.commitmentForm.controls.address.disable();
    this.commitmentForm.controls.ecrmAccountNumber.disable();
    this.commitmentForm.controls.clientTCV.disable();
    this.commitmentForm.controls.clientACV.disable();
    
    this.commitmentForm.controls.clientName.valueChanges.subscribe(name => {
      const client = this.ecrmClients.find((el: any) => el.clientName === name);
      this.clientNameSelected(client);
    });

    this.commitmentForm.controls.duns.valueChanges.subscribe(duns => {
      const client = this.ecrmClients.find((el: any) => el.duns === duns);
      this.clientDUNSSelected(client);
    });
  }

  clientNameSelected(item): any {
    if (!item)  return;
    this.commitmentForm.patchValue({
      clientId: item.clientId,
      address: item.address,
      duns: item.duns,
      ecrmAccountNumber: item.ecrmAccountNumber,
      clientTCV: item.clientTCV,
      clientACV: item.clientACV
    }, { emitEvent: false });
  }

  clientDUNSSelected(item): any {
    if (!item)  return;

    this.commitmentForm.patchValue({
      clientId: item.clientId,
      address: item.address,
      clientName: item.clientName,
      ecrmAccountNumber: item.ecrmAccountNumber,
      clientTCV: item.clientTCV,
      clientACV: item.clientACV
    }, { emitEvent: false });
  }
}
