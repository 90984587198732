<div class="modal fade bd-example-modal-lg" data-backdrop="static" id="addCommitmentUsers" tabindex="-1" role="dialog"
    style="display: none;" aria-hidden="true" #content>
    <div class="modal-dialog modal-lg mt-120" role="document" style="max-width: 700px;">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            Select Client users
          </h4>
        </div>
        <div class="modal-body">      
            <div class="legend-wrapper ml-0 mr-0 mt-2 mb-3">
              <span class="legend-text">
                <span style="text-align: left;">
                  <i class="material-icons">info</i> Selected commitment is part of Custom Client Assignment , please select at least one client user to proceed.
                </span>
              </span>
            </div>
            <label>Select client users to assign this Project:</label>
            <br />
        <div ml-3 mr-8 mt-3 mb-2>
            <ng-select [items]="clientExecutiveSuggestions" bindLabel="suggestionText" bindValue="id" [multiple]="true"
                       groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
                       [closeOnSelect]="false" [virtualScroll]="true" [clearOnBackspace]="false" [ngModelOptions]="{standalone: true}"
                       placeholder="Select Users" [(ngModel)]="SelectedCEUsers" (change)="onSelectCEUsers()" style="max-width:500px;">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> Select All
              </ng-template>

              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.suggestionText}}
              </ng-template>
              <ng-template ng-multi-label-tmp>
                <span class="ng-value-label">
                  {{SelectedCEUsers.length}}
                  Item{{SelectedCEUsers.length > 1 ? 's' : ''}}
                </span>
              </ng-template>
            </ng-select>
        </div>
        <div class="ml-0 mr-0 mt-5 mb-5">
          <table class="table table-hover" *ngIf="SelectedCEUsersDetails">
            <thead>
              <tr role="row">
                <th role="columnheader">Name</th>
                <th role="columnheader">Email</th>
                <th role="columnheader">Group</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let SelectedCEUser of SelectedCEUsersDetails">
                <td role="cell">{{SelectedCEUser.name}}</td>
                <td role="cell">{{SelectedCEUser.email}}</td>
                <td role="cell">{{SelectedCEUser.restrictedUserGroup}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
            <div class="modal-footer fdl-2">
                <button type="button" class="btn fdl-btn btn-secondary-light " data-dismiss="modal"
                    (click)="onCancel()"> Cancel </button>
                <button type="button" [disabled]="disableSaveButton"
                    class=" btn fdl-btn btn-primary-orange ml-2" (click)="onSaveClick()"> Save </button>
            </div>
        </div>
    </div>
</div>
