import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { UserService } from "../services/user.service";
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { filter, takeUntil } from 'rxjs/operators';
import { InteractionStatus, EventMessage, EventType } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { ROLES } from "../core/constants";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html"
})
export class DashboardComponent implements OnInit {
  userDetails: any;
  private readonly _destroying$ = new Subject<void>();
  constructor(private userService: UserService, private router: Router,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService) {
    this.userDetails = this.userService.getUserListData();
  }

  ngOnInit(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        if (result && result.payload && result.payload['account']) {
          //this.checkoutAccount();
        }
      });
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        //this.checkoutAccount();
      });
    //this.router.navigate(["dashboard/project"]);
    if ((this.userDetails.roles.some(r => r.roleTitle === 'Client Admin') && this.userDetails.roles.some(r => r.roleTitle === ROLES.Client_Executive) && this.userDetails.roles.length === 2) ||
      this.userDetails.roles.some(r => r.roleTitle === 'Client Admin') && this.userDetails.roles.length === 1) {
      this.router.navigate(["commitmentdashboard"]);
    } else {
      this.router.navigate(["dashboard/mydashboard"]);
    }
  }

  checkoutAccount() {
    //  const accounts = this.authService.instance.getAllAccounts();
    //  if (accounts.length > 0) {
    //   this.router.events.pipe(
    //    filter(event => event instanceof NavigationEnd)
    //   ).subscribe((event: NavigationEnd) => {
    //    if (event['url'] === '/') {
    //    this.router.navigate(["dashboard"]);
    //   }
    //   });
    //this.router.navigate(["dashboard/project"]);
    this.router.navigate(["dashboard/mydashboard"]);
    //} 
  }
}
